import React, {useState, useEffect} from 'react';
import {Button, Form, Input, Message, Modal, Table, TextArea} from "semantic-ui-react";
import {rgEditOrders, rgReject} from "../../api/registries";
import {getValuePassZero, restrictionFloat} from "../../services/utils";
import {EditField} from "../dictionaries/editField";

const RgRejectModal = ({ selectedRows, open, onClose, fmid: fmidInit, ids: idArray, fetchData, isEdit, params: editParams = {} }) => {
    const [params, setParams] = useState(editParams);
    const [progress, setProgress] = useState(false);
    const [rows, setRows] = useState([]);

    const fmid = fmidInit || ((selectedRows || []).length === 1 ? (selectedRows[0].fmidc || selectedRows[0].fmid) : null);

    const isMass = !!(selectedRows || []).length && !fmid;

    useEffect(() => {
        open && setParams(editParams);
    }, [open]);

    const handleClose = () => {
        setRows([]);
        setParams({});
        onClose();
    };

    const handleChange = (e, {value, name}) => {
        setParams(params => ({
            ...params,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        setProgress(true);
        const ids = idArray || (selectedRows || []).map(row => row.id);

        try {
            if (!isMass) {
                if (isEdit) {
                    await rgEditOrders({
                        ids,
                        ...params,
                    });
                } else {
                    await rgReject([{
                        ids,
                        ...params,
                    }]);
                }
            } else {
                await rgReject(rows.map(row => ({
                    ids: [row.id],
                    [cols[1].key]: row[cols[1].key],
                    [cols[2].key]: row[cols[2].key]
                })));
            }

            await fetchData();
        } finally {
            handleClose();
            setProgress(false);
        }
    };


    const form = <>
        <Form.Field>
            <label>Комментарий</label>
            <TextArea
                placeholder='Введите комментарий'
                value={params.comment}
                maxLength={1000}
                name="comment"
                onChange={handleChange}
            />
        </Form.Field>
        <Form.Field>
            <label>Стоимость без НДС</label>
            <Input
                type="number"
                min="0"
                step="0.1"
                placeholder={'Стоимость без НДС'.t}
                value={getValuePassZero(params.clientCostWithoutVAT)}
                onChange={e => handleChange(e, {name: 'clientCostWithoutVAT', value: restrictionFloat(e.target.value, 3)})}
            />
        </Form.Field>
    </>;

    const cols = [
        {
            key: 'number',
            name: 'Номер заявки',
            width: '2'
        },
        {
            key: 'clientCostWithoutVAT',
            name: 'Стоимость без НДС',
            width: '5',
            fieldType: 'number',
            isRequired: false
        },
        {
            key: 'comment',
            name: 'Комментарий',
            isRequired: true
        },
    ];

    useEffect(() => {
        (selectedRows && isMass) && setRows(selectedRows.map(row => ({
            id: row.id,
            number: row.fmidc || row.fmid,
        })));
    }, [selectedRows]);

    const rowChange = (id, {value, name}) => {
        setRows(rows => {
            let rowsNew = [...rows];
            const index = rows.findIndex(row => row.id === id);
            rowsNew[index] = {
                ...rowsNew[index],
                [name]: value
            };
            return rowsNew;
        });
    };

    const massForm = <>
        <Table celled singleLine sortable >
            <Table.Header>
                {
                    cols.map(col => <Table.HeaderCell
                        width={col.width}
                        textAlign='center'
                    >
                        {col.name}{col.isRequired && <span className='is-required'> *</span>}
                    </Table.HeaderCell>)
                }
            </Table.Header>
            <Table.Body>
                {
                    (rows || []).map(row => {
                        const onChange = (e, data) => {
                            rowChange(row.id, data);
                        };

                        return <Table.Row>
                            {
                                cols.map(col => <Table.Cell width={col.width}>
                                    {
                                        col.key === 'number'
                                            ? row.number
                                            : <EditField row={row} col={col} handleChange={onChange}/>
                                    }
                                </Table.Cell>)
                            }
                        </Table.Row>;
                    })
                }
            </Table.Body>
        </Table>
    </>;

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Modal.Header>{isEdit ? 'Редактирование Комментария и Общей стоимости без НДС' : 'Оставьте Комментарий и Общую стоимость без НДС'}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Message
                        info
                        icon='info'
                        content={isEdit ? <span>Вы можете оставить или изменить Комментарий и Общую стоимость без НДС по всей заявке.</span>
                            : <span>Оставьте Комментарий, почему не подтверждаете заявк{isMass ? 'и' : <span>у <b> {fmid}</b></span>}.
                            Общую стоимость без НДС по всей заявке Вы можете указать в отдельном поле.</span>}
                    />
                    {
                        isMass
                            ? massForm
                            : form
                    }
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>Отменить</Button>
                <Button
                    color="green"
                    loading={progress}
                    disabled={!isMass
                        ? (!params.comment || progress)
                        : !rows.every(row => cols.filter(col => col.isRequired).map(col => col.key).every(key => !!row[key]))}
                    onClick={handleSubmit}
                >
                    {isEdit ? 'Сохранить изменения' : 'Сохранить'}
                </Button>
            </Modal.Actions>
        </Modal>
    )
};

export default RgRejectModal;
