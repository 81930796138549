import moment from "moment";

export function compareTime(times = '') {
    const timesArray = times.split('-');
    const time1 = timesArray[0] || '';
    const time2 = timesArray[1] || '';
    let date1 =  new Date();
    date1 = date1.setHours.apply(date1, time1.split(":"));
    let date2 =  new Date();
    date2 = date2.setHours.apply(date2, time2.split(":"));
    return date2 - date1;
}

export function normalizeTimePeriod(val) {
    const vals = (val.length === 6 && val[5] !== '-') ? [val.slice(0, 5), val.slice(-1)] :  val.split('-');
    return val.length > 5 ? `${normalizeTime(vals[0] || '')}-${normalizeTime(vals[1] || '')}` : normalizeTime(vals[0] || '');
}

export function normalizeTime(val) {

    if (!val) return val;

    const valLen = val.length;

    const lastChar = val.slice(-1);

    // remove non digits
    if (isNaN(parseInt(lastChar, 10))) {
        return val.substr(0, valLen - 1);
    }

    switch (valLen) {
        case 1:
            break;

        case 2:
            const firstDigit = Number.parseInt(val[0]);

            if (firstDigit === 2 && Number.parseInt(lastChar) > 3) {
                return val.substr(0, valLen - 1);
            } else if (firstDigit > 2) {
                return `0${firstDigit}:${lastChar}`;
            }

            break;
        case 3:

            if (Number.parseInt(lastChar) > 5) {
                return val.substr(0, valLen - 1);
            }

            if (!val.includes(':')) {

                const valFixed = [...val];

                valFixed.splice(2, 0, ':');

                val = valFixed.join('');
            }

            break;
        case 4:
            break;
        case 5:
            if (!new moment(val, 'HH:mm', true).isValid())
                val = "";
            break;

        default:
            if (valLen > 5) {
                val = val.substr(0, 5);
            }
            break;
    }



    return val;
}

export function checkTime(str) {
    if (!str) return true;
    return new moment(str, 'HH:mm', true).isValid();
}
