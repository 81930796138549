import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {Form, Input} from 'semantic-ui-react';
import {PointTypeEnum} from '../../../api/model/Point';
import {PointTimeslot} from '../../../api/model/PointTimeslot';
import AppButtonLink from '../../../components/buttons/AppButtonLink';
import InputDate from '../../../components/inputs/InputDate';
import T from '../../../components/Translate';
import WbPointCardTabsMainDateCalc from './WbPointCardTabsMainDateCalc';
import WbPointCardTabsMainTimeslot from './WbPointCardTabsMainTimeslot';
import UserPermissions from '../../../api/model/UserPermissions';
import {ContextUser, ContextWb} from '../../../services/context';
import {WaybillStatusEnum, WaybillTypeEnum} from "../../../api/model/Waybill";
import {toast} from "../../../services/toast";

export default function PointCardTabsMain({ data, handleDataChange, isLoading, oppositeDate, wbId, wbType, needCalc, pointsAll, isClient, disabled, isRoute }) {

    const dateFormat = "YYYY-MM-DD";
    const dateFromProps = data.arrivalDatePlan && moment(data.arrivalDatePlan, dateFormat).toDate();

    const contextUser = useContext(ContextUser);
    const contextWb = useContext(ContextWb);
    const isPoolingDraft = contextWb.wb.status === WaybillStatusEnum.DRAFT && contextWb.wb.isPooling;
    const pasPermissions = contextUser.current.permissions.includes(UserPermissions.WAYBILL_EDIT);
    const disabledDays = pasPermissions ? [] : [{ before: moment().add(1, 'days').toDate() }];
    const [errors, setErrors] = useState([]);
    let dateText, timeText;

    useEffect(() => {
        setErrors([]);

        const findIndex = findIndexPointAfterAndBeforeTimeSlot();
        const findIndexPointAfter = findIndex.after;
        const findIndexPointBefore = findIndex.before;
        const isFromBeforeTo = (data.arrivalTimeslotPlan && data.arrivalTimeslotPlan.to) && data.arrivalTimeslotPlan.to >= data.arrivalTimeslotPlan.from;
        const isValidTimeSlot = (data.pointType === PointTypeEnum.FM && isFromBeforeTo) || true;

        const isZero = time => time === '00:00';
        const isEqualTime = () => {
            const current = pointsAll.find(item => item.id === data.id);
            //if (current.isLoading) {
                return current && pointsAll.some(pl => pl.isLoading !== current.isLoading && pl.arrivalTimeslotPlan && current.arrivalTimeslotPlan && pl.arrivalDatePlan === current.arrivalDatePlan && pl.arrivalTimeslotPlan.from === current.arrivalTimeslotPlan.from)
            //}

            //return  false;
        };

        let errs = [];
        setErrors(prevState => {
            let err = [...prevState];

            if ((findIndexPointAfter
                || isZero(data.arrivalTimeslotPlan && data.arrivalTimeslotPlan.from)
                || isEqualTime())
                && !err.includes('from')) {
                    errs.push('from');
            }
            if (((findIndexPointBefore || isZero(data.arrivalTimeslotPlan && data.arrivalTimeslotPlan.to)) && !err.includes('to')) || !isValidTimeSlot) {
                    errs.push('to');
            }

            return errs;
        });
    }, [data, pointsAll]);

    function findIndexPointAfterAndBeforeTimeSlot() {
        if (wbType === WaybillTypeEnum.FTL) {

            const findIndexPointBefore = pointsAll.find(i => i.positionNumber === 1 && !i.isLoading && data.arrivalTimeslotPlan);

            return {after: findIndexPointBefore && pointsAll.find(i => i.isLoading && i.arrivalTimeslotPlan), before: findIndexPointBefore && pointsAll.find(i => i.isLoading && i.arrivalTimeslotPlan)}
        }
        const findPointsAfter = pointsAll.filter(i => data.arrivalDatePlan !== null && i.arrivalDatePlan === data.arrivalDatePlan && i.positionNumber > data.positionNumber);

        const findPointsBefore = pointsAll.filter(i => data.arrivalDatePlan !== null && i.arrivalDatePlan === data.arrivalDatePlan && i.positionNumber < data.positionNumber);

        const findIndexPointAfter = findPointsAfter.find(i => ((data.arrivalTimeslotPlan && i.arrivalTimeslotPlan) && (i.arrivalTimeslotPlan.from < data.arrivalTimeslotPlan.from)) || ((i.arrivalTimeslotPlan &&  data.arrivalTimeslotPlan) && (i.arrivalTimeslotPlan.from === data.arrivalTimeslotPlan.from)));
        const findIndexPointBefore = findPointsBefore.find(i => ((data.arrivalTimeslotPlan && i.arrivalTimeslotPlan) && (i.arrivalTimeslotPlan.from > data.arrivalTimeslotPlan.from)) || ((i.arrivalTimeslotPlan &&  data.arrivalTimeslotPlan) && (i.arrivalTimeslotPlan.from === data.arrivalTimeslotPlan.from)));

        return {after: findIndexPointAfter, before: findIndexPointBefore}
    }

    function isValidTimeSlot() {
        const findIndex = findIndexPointAfterAndBeforeTimeSlot();
        return !findIndex.after && !findIndex.before
    }

    switch (data.pointType) {

        case PointTypeEnum.FM:
            dateText = "Дата привоза на ФМ";
            timeText = "Время привоза на ФМ";

            break;
        case PointTypeEnum.CLIENT_WAREHOUSE:
            if (isLoading) {
                dateText = "Дата отгрузки со склада клиента";
                timeText = "Время отгрузки со склада клиента";
            } else {
                dateText = "Дата прибытия на адрес";
                timeText = "Время прибытия на адрес";
            }
            break;
        default:
            dateText = "Дата прибытия на адрес";
            timeText = "Время прибытия на адрес";
            break;
    }

    const handleDateChange = function (val) {
        if (val && val instanceof Date) {
            val = moment(val).format(dateFormat);
        }
        handleDataChange('arrivalDatePlan', val);

		if (!isLoading) {
			// доставка в не рабочие дни
		}
    };

    const handleTimeslotChange = function (str) {
        if (str) {
            const [from, to] = str.split('-');
            if ((from.length === 5 && to.length === 5) && (from !== data.arrivalTimeslotPlan.from || to !== data.arrivalTimeslotPlan.to) && from > to) {
                toast.warning('При указании времени с переходом на другую дату, указывается минимальная дата, к которой относится время');
            }
        }

        const val = new PointTimeslot(str);
		handleDataChange('arrivalTimeslotPlan', val);

        // todo: replace handleDataChange with direct update at once
		// if (!isLoading) {
        //     const key = PointServiceEnum.TIMESLOT;

		// 	if (val.getDifferenceInHours() < 5) { // less then 5 hours
		// 		if (!data.services || !data.services.find(s => s.key === key)) {
		// 			const newServices = [...data.services];
        //             newServices.push(new PointService(key));
        //             handleDataChange('services', newServices);
		// 		}
		// 	} else {
		// 		if (data.services && data.services.length) {
		// 			const deletableIndex = data.services.findIndex(s => s.key === key);
		// 			if (deletableIndex > -1) {
		// 				const newServices = [...data.services];
        //                 newServices.splice(deletableIndex, 1);
        //                 handleDataChange('services', newServices);
		// 			}

		// 		}
		// 	}
		// }
    };

    function getMaxDate(type) {
        if (type === WaybillTypeEnum.LTL || type === WaybillTypeEnum.PlanReturn || type === WaybillTypeEnum.Rail || type === WaybillTypeEnum.RailLTL) {
            const findPointsAfter = pointsAll.filter(i => i.positionNumber > data.positionNumber);
            const findIndexPointAfter = findPointsAfter.find(i => i.positionNumber > data.positionNumber && i.arrivalDatePlan);
            if (findIndexPointAfter) {
                return findIndexPointAfter.arrivalDatePlan;
            }
        }

        if (type === WaybillTypeEnum.FTL) {
            const point = pointsAll.find(i => isRoute ? i.id === data.id : i.positionNumber === data.positionNumber) || {};

            if (point.isLoading && (isRoute || point.positionNumber === 1)) {
                const obj = pointsAll.find(item => (isRoute || item.positionNumber > 1) && !item.isLoading) || {};
                return obj.arrivalDatePlan
            }

            const findPointsBefore = pointsAll.filter(i => i.positionNumber < data.positionNumber);
            const findIndexPointBefore = findPointsBefore.find(i => i.positionNumber === 1 && !i.isLoading && i.arrivalDatePlan);

            if (findIndexPointBefore && point.isLoading) {
                return findIndexPointBefore.arrivalDatePlan
            }

            return null;
        }

        return null;
    }

    function getMinDate(type) {
        const findPointsBefore = pointsAll.filter(i => i.positionNumber < data.positionNumber);
        const findIndexPointBefore = findPointsBefore.find(i => i.positionNumber < data.positionNumber && i.arrivalDatePlan);

        if (type === WaybillTypeEnum.LTL || type === WaybillTypeEnum.PlanReturn || type === WaybillTypeEnum.Rail || type === WaybillTypeEnum.RailLTL) {
            if (findIndexPointBefore) {
                return findIndexPointBefore.arrivalDatePlan;
            }
        }

        if (type === WaybillTypeEnum.FTL) {
            const point = pointsAll.find(i => isRoute ? i.id === data.id : i.positionNumber === data.positionNumber) || {};
            if (!point.isLoading) {
                const obj = pointsAll.find(item => isRoute ? item.isLoading : item.positionNumber === 1) || {};
                return obj.arrivalDatePlan;
            }

            return null;
        }

        return null;
    }
    return (
        <Form size="small">
			<Form.Group widths="equal">
				<Form.Field required>
                    <label className="display-inline-block" style={{marginBottom: '3px', opacity: isClient ? .45 : 1}}><T>{dateText}</T></label>
                    {needCalc && <WbPointCardTabsMainDateCalc
                        wbId={wbId}
                        wbPointId={data.id}
                        oppositeDate={oppositeDate}
                        onChange={val => handleDateChange(val)}
                        isClient={isClient}>
                        <AppButtonLink
                            id={data.id}
                            disabled={!oppositeDate}
                            style={{
                                fontSize: '12px',
                                paddingTop: "1px",
                                float: 'right'
                            }}
                        >
                            <T>рассчитать</T>
                        </AppButtonLink>
                    </WbPointCardTabsMainDateCalc>}
                    <InputDate
                        id={data.id}
                        icon="calendar outline"
                        placeholder={"Дата прибытия".t}
                        position="bottom center"
                        maxDate={getMaxDate(wbType)}
                        minDate={getMinDate(wbType)}
                        onChange={val => handleDateChange(val)}
                        value={dateFromProps}
                        disabledDays={disabledDays || disabled}
                        style={{opacity: isClient || disabled ? .45 : 1}}
                        disabled={isClient || isPoolingDraft || disabled}
                    />
                </Form.Field>
                <WbPointCardTabsMainTimeslot
                    id={data.id}
                    errors={errors}
                    hubLoading={data.hubLoading}
                    value={data.arrivalTimeslotPlan}
                    onChange={val => handleTimeslotChange(val)}
                    disabled={isClient || isPoolingDraft || disabled}
                >
                    <Form.Field required error={errors.includes('from') || errors.includes('to')}>
                        <label style={{opacity: isClient ? .45 : 1}}>{timeText.t}</label>
                        <Input
                            id={`input_arrivalTimeslotPlan-${data.id}`}
                            readOnly
                            icon="clock outline"
                            placeholder={"Время прибытия".t}
                            //onChange={e => handleTimeslotChange(e.target.value)}
                            value={new PointTimeslot(data.arrivalTimeslotPlan).toString()}
                            disabled={isClient || isPoolingDraft || disabled}
                        />
                    </Form.Field>

                </WbPointCardTabsMainTimeslot>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field>
                    <label>{"Контакт".t}</label>
                    <Form.Input
                        id={`input_contact-${data.id}`}
                        icon="user"
                        placeholder={"ФИО".t}
                        maxLength={35}
                        onChange={e => handleDataChange('contact', { ...data.contact, name: e.target.value && e.target.value.replace(/[^\D]/g, '')})}
                        value={(data.contact && data.contact.name) || ''}
                        disabled={isClient || isPoolingDraft || disabled}
                    />
                </Form.Field>
                <Form.Field>
                    <label>{"Телефон".t}</label>
                    <Form.Input
                        id={`input_phone-${data.id}`}
                        icon="call"
                        placeholder={"XXX XXX-XX-XX".t}
                        maxLength={35}
                        onChange={e => handleDataChange('contact', { ...data.contact, phone: e.target.value && e.target.value.replace(/[a-zA-Zа-яА-Я]/g, '')})}
                        value={(data.contact && data.contact.phone) || ''}
                        disabled={isClient || isPoolingDraft || disabled}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Field>
                <label>{"Комментарий".t}</label>
                <Form.Input
                    id={`input_comment-${data.id}`}
                    placeholder={"Комментарий".t}
                    value={data.comment || ''}
                    onChange={e => handleDataChange('comment', e.target.value)}
                    disabled={isClient || isPoolingDraft || disabled}
                />
            </Form.Field>
        </Form>
    );
}
