import O, {OptionWithIcon} from "../../components/Option";
import {parseHeaders} from "../../components/bigTable/const";

export const ImportFormStatusEnum = {
    PENDING: 'PENDING',
    DONE: 'READY'
};
export const ImportFormFileStatusEnum = {
    WAITING_PROCESSING: 'WaitingProcessing',
    IN_PROGRESS: 'InProgress',
    ERROR: 'Error',
    COMPLETED: 'Completed',
    SKIP_PROCESSING: 'SkipProcessing',
};
export const ImportFormStatusOptions = [
    new O(ImportFormStatusEnum.PENDING, "Подготовка"),
    new O(ImportFormStatusEnum.DONE, "Готова")
];

export const DisabledFormStatuses = [
    ImportFormFileStatusEnum.WAITING_PROCESSING,
    ImportFormFileStatusEnum.IN_PROGRESS,
    ImportFormStatusEnum.PENDING
];

export const ImportFormFileStatusOptions = [
    new OptionWithIcon(ImportFormFileStatusEnum.WAITING_PROCESSING, "В очереди на обработку", null, 'upload', 'grey'),
    new OptionWithIcon(ImportFormFileStatusEnum.IN_PROGRESS, "Находится в обработке", null, 'sync', 'orange'),
    new OptionWithIcon(ImportFormFileStatusEnum.COMPLETED, "Обработка завершена", null, 'check', 'green'),
    new OptionWithIcon(ImportFormFileStatusEnum.ERROR, "Ошибка при обработке", null, 'x', 'red'),
];

export function getImportFormStatusName(status) {
    const option = ImportFormStatusOptions.find(o => o.key === status);
    return option ? option.text : status;
}

export function getImportFormStatusColor(status) {
    switch (status) {
        case ImportFormStatusEnum.PENDING:
            return "orange";
        case ImportFormStatusEnum.DONE:
            return 'teal';
        default:
            return null;
    }
}

export const RuleStatusEnum = {
    WARNING: 0,
    DONE: 1,
    ERROR: 2,
};
export const RuleStatusOptions = [
    new OptionWithIcon(RuleStatusEnum.WARNING, "Ошибка", null, 'warning circle', 'yellow'),
    new OptionWithIcon(RuleStatusEnum.ERROR, "Ошибка", null, 'remove circle', 'red'),
    new OptionWithIcon(RuleStatusEnum.DONE, "Готово", null, 'check circle', 'green'),
];

export const headerRules = [
    {
        name: 'Статус',
    },
    {
        name: 'Параметр заявки',
    },
    {
        name: 'Правило',
    },
    {
        name: 'Значение правила',
    }
];

export const dataTypes = {
    DEFAULT: 0,
    STRING: 1,
    INT: 2,
    DECIMAL: 3
};

export const fieldTypes = {
    DROPDOWN: 'dropdown',
    NUMBER: 'number',
    STRING: 'text',
    MULTISELECT: 'multiselect',
    PLUS_DAYS: 'plusDays',
};

export const fieldTypeByDataType = {
    [dataTypes.DEFAULT]: fieldTypes.STRING,
    [dataTypes.STRING]: fieldTypes.STRING,
    [dataTypes.INT]: fieldTypes.NUMBER,
    [dataTypes.DECIMAL]: fieldTypes.NUMBER,
};

export const serviceParametersForRules = [
    {
        columnName: 'dataPositioningType',
        displayName: 'Тип расположения',
        isRequired: true
    },
    {
        columnName: 'startPosition',
        displayName: 'Номер первой строки',
        dataType: dataTypes.INT,
        isRequired: true
    },
    {
        columnName: 'isConsolidation',
        displayName: 'Консолидация',
        isRequired: true
    },
];

export const ProcessingTypesEnum = {
    CONSTANT: 1,
    CELL: 2,
    SCHEDULE: 3,
    AUTOMATIC: 4,
    REPLACE: 11,
    REPLACE_BY_TEMPLATE: 12,
    REPLACE_CUSTOM: 13,
    SPLITTERS: 21,
    TRIM: 22,
    POSITION: 23
};

export const ProcessingTypesOptions = [
    new O(ProcessingTypesEnum.CONSTANT, "Константа"),
    new O(ProcessingTypesEnum.CELL, "Номер столбца"),
    new O(ProcessingTypesEnum.SCHEDULE, "По графику"),
    new O(ProcessingTypesEnum.AUTOMATIC, "Автоматическое определение"),
    new O(ProcessingTypesEnum.REPLACE, "Замена"),
    new O(ProcessingTypesEnum.REPLACE_BY_TEMPLATE, "Замена по шаблону"),
    new O(ProcessingTypesEnum.REPLACE_CUSTOM, "Настраиваемая замена"),
    new O(ProcessingTypesEnum.SPLITTERS, "Тип разделителя"),
    new O(ProcessingTypesEnum.TRIM, "Обрезка"),
    new O(ProcessingTypesEnum.POSITION, "Позиция"),
];

export const getNameProcessingType = (type) => {
    return type && (ProcessingTypesOptions.find(o => o.value === type) || {}).text;
};

export const FieldTypesEnum = {
    BASIC: 0,
    SPLITED: 1,
    UNIQUE_SPLITED: 2,
    DATE: 6,
    TIME: 7,
    COMPANY: 11,
    COMPANY_TYPE: 12,
    CAR_TYPE: 13,
    TEMPERATURE: 14,
};

export const defaultTemplate = {
    [FieldTypesEnum.DATE]: '11.04.1994',
    [FieldTypesEnum.TIME]: '6:30'
};

export const optionsByProcessingDescriptors = (array = [], processingType, selectedOptionsFromOthers = [], ignoreType) => {
    const arr = processingType ? (array.find(item => item.processingType === processingType) || {}).secondaryDescriptors : array;

    return !(arr || []).length
        ? []
        : arr.filter(o => {
            return !(o.isSingleInstance && [...selectedOptionsFromOthers, ignoreType].includes(o.processingType));
        }).map(item => {
            const itemData = ProcessingTypesOptions.find(o => o.value === item.processingType);
            return {
                ...item,
                ...itemData
            };
        });
};

export const LocationTypesEnum = {
    COLUMNS: 0,
    STRINGS: 1,
    CELLS: 2,
    HYBRID: 3
};
export const LocationTypesOptions = [
    new O(LocationTypesEnum.COLUMNS, "Столбцы"),
    new O(LocationTypesEnum.STRINGS, "Строки"),
    new O(LocationTypesEnum.CELLS, "Ячейки"),
    new O(LocationTypesEnum.HYBRID, "Гибрид"),
];

export const BoolEnum = {
    YES: true,
    NO: false
};

export const BoolOptions = [
    new O(BoolEnum.YES, 'Да'),
    new O(BoolEnum.NO, 'Нет'),
];

export const TrimTypesEnum = {
    START: 'start',
    END: 'end'
};

export const TrimTypesOptions = [
    new O(TrimTypesEnum.START, 'в начале'),
    new O(TrimTypesEnum.END, 'в конце'),
];

export const ServiceParamsOptions = {
    [serviceParametersForRules[0].columnName]: {
        type: fieldTypes.DROPDOWN,
        options: LocationTypesOptions
    },
    [serviceParametersForRules[1].columnName]: {
        type: fieldTypes.NUMBER
    },
    [serviceParametersForRules[2].columnName]: {
        type: fieldTypes.DROPDOWN,
        options: BoolOptions
    }
};

export const defaultData = {
    [serviceParametersForRules[0].columnName]: {
        ruleType: LocationTypesOptions[0].value,
    },
    [serviceParametersForRules[1].columnName]: {
        ruleType: 2
    },
    [serviceParametersForRules[2].columnName]: {
        ruleType: BoolOptions[1].value
    }
};

export const disabledParams = [
    serviceParametersForRules[0].columnName
];

export const latinLettersValue = (str, maxValue = 200) => {
    if (!/^[a-zA-Z]+$/.test(str)) {
        return false;
    } else {
        return parseHeaders(str) < maxValue;
    }
};

export const dateInputs = [
    {
        name: 'yearFormat',
        optionsName: 'yearFormats',
        label: 'Формат написания года',
        inputType: fieldTypes.DROPDOWN
    },
    {
        name: 'monthFormat',
        optionsName: 'monthFormats',
        label: 'Формат написания месяца',
        inputType: fieldTypes.DROPDOWN
    },
    {
        name: 'splitterType',
        optionsName: 'splitterTypes',
        label: 'Тип разделителя',
        inputType: fieldTypes.DROPDOWN
    },
    {
        name: 'orderFormat',
        optionsName: 'orderFormats',
        label: 'Порядок указания дд, мм, гг',
        inputType: fieldTypes.DROPDOWN
    },
];

export const timeInputs = [
    {
        name: 'splitterType',
        optionsName: 'splitterTypes',
        label: 'Тип разделителя',
        inputType: fieldTypes.DROPDOWN
    },
    {
        name: 'amPm',
        optionsName: 'bool',
        label: 'Указание AM/PM',
        inputType: fieldTypes.DROPDOWN
    },
    {
        name: 'timeOrderFormat',
        optionsName: 'timeOrderFormats',
        label: 'Порядок указания чч, мм, сс',
        inputType: fieldTypes.DROPDOWN
    },
];

export const nameTrimType = 'trimType';
export const nameTrimValue = 'value';

export const defaultSplitters = [';'];

export const importFormModesEnum = {
    FORM: 0,
    SCHEDULES: 1,
    POINTS: 2
};

export const importFormModes = [
    {
        name: 'Адреса',
        code: importFormModesEnum.POINTS
    },
    {
        name: 'Графики',
        code: importFormModesEnum.SCHEDULES
    }
];