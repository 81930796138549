const headers1 = [
    {
        key: 'fmidc',
        text: 'Номер заявки',
        sortable: false,
    },
    {
        key: 'mainfmid',
        text: 'Номер основной заявки по возврату',
        sortable: false,
    },
    {
        key: 'type',
        text: 'Тип заявки',
        sortable: false,
    },
    {
        key: 'fmid',
        text: 'Номер основной заявки по маршруту',
        sortable: false,
    },
    {
        key: 'serviceType',
        text: 'Тип сервиса доставки',
        sortable: false,
    },
    {
        key: 'ht',
        text: 'НТ',
        sortable: false
    },
    {
        key: 'companyName',
        text: 'Клиент',
        sortable: false
    },
    {
        key: 'recipientOrderNo',
        text: 'Номер заказа грузополучателя',
        sortable: false,
    },
    {
        key: 'torg12No',
        text: 'Номер Торг 12 / УПД',
        sortable: false,
    },
    {
        key: 'shipperOrderNo',
        text: 'Номер заказа грузоотправителя',
        sortable: false,
    },
    {
        key: 'consignor',
        text: 'Грузоотправитель',
        sortable: false,
    },
    {
        key: 'loadingCity',
        text: 'Город загрузки',
        sortable: true
    },
    {
        key: 'loadingAddress',
        text: 'Адрес загрузки',
        sortable: true
    },
    {
        key: 'loadingDate',
        text: 'Дата загрузки',
        sortable: false
    },
    {
        key: 'loadingTimeFrom',
        text: 'Время загрузки С',
        sortable: true
    },
    {
        key: 'loadingTimeTo',
        text: 'Время загрузки ПО',
        sortable: true
    },
    {
        key: 'consignee',
        text: 'Грузополучатель',
        sortable: false
    },
    {
        key: 'unloadingCity',
        text: 'Город выгрузки',
        sortable: true
    },
    {
        key: 'unloadingAddress',
        text: 'Адрес выгрузки',
        sortable: true
    },
    {
        key: 'unloadingCityZone',
        text: 'Зона выгрузки',
        sortable: false
    },
    {
        key: 'unloadingDate',
        text: 'Дата выгрузки',
        sortable: false
    },
    {
        key: 'unloadingTimeFrom',
        text: 'Время разгрузки С',
        sortable: true
    },
    {
        key: 'unloadingTimeTo',
        text: 'Время разгрузки ПО',
        sortable: true
    },
];

const headersLoadUnits = [
    {
        key: 1,
        text: 'BT',
        sortable: false
    },
    {
        key: 2,
        text: 'BX',
        sortable: false
    },
    {
        key: 3,
        text: 'EP',
        sortable: false
    },
    {
        key: 4,
        text: 'LP',
        sortable: false
    },
    {
        key: 5,
        text: 'OT',
        sortable: false
    },
    {
        key: 6,
        text: 'PP',
        sortable: false
    },
    {
        key: 7,
        text: 'HP',
        sortable: false
    },
];

const headers1Ext = [
    {
        key: 'weight',
        text: 'Вес',
        sortable: false
    },
    {
        key: 'volume',
        text: 'Объем',
        sortable: false
    },
    {
        key: 'clientDimensionalWeight',
        text: 'Объемный вес клиента',
        sortable: false
    },
    {
        key: 'fmDimensionalWeight',
        text: 'Объемный вес FM',
        sortable: false
    },
    {
        key: 'cargoType',
        text: 'Тип продукции',
        sortable: false,
    },
];

const headersTrip = [
    {
        key: 'trip1Number',
        text: 'Номер первой поездки',
    },
    {
        key: 'trip1TransportType',
        text: 'Тип ТС по заявке',
    },
    {
        key: 'trip1Driver',
        text: 'Водитель из первой поездки',
    },
    {
        key: 'trip1Transport',
        text: 'ТС из первой поездки',
    },
    {
        key: 'trip2Number',
        text: 'Номер поездки по доставке',
        sortable: true
    },
    {
        key: 'trip2TransportType',
        text: 'Тип ТС по заявке',
    },
    {
        key: 'trip2Driver',
        text: 'Водитель по доставке',
        sortable: true
    },
    {
        key: 'trip2Transport',
        text: 'ТС по доставке',
        sortable: true
    },
    {
        key: 'loadingComment',
        text: 'Комментарий на загрузке',
        sortable: false
    },
    {
        key: 'unloadingComment',
        text: 'Комментарий на разгрузке',
        sortable: false
    },
];

export const headersComment = [
    {
        key: 'loadingComment',
        text: 'Комментарий на загрузке',
        sortable: false
    },
    {
        key: 'unloadingComment',
        text: 'Комментарий на разгрузке',
        sortable: false
    },
];

const headersServices = [
    {
        key: 101,
        text: 'Внутригор.дост. Почас.оплата'
    },
    {
        key: 102,
        text: 'Доставка (все виды)'
    },
    {
        key: 103,
        text: 'Доп.точка выгрузки'
    },
    {
        key: 104,
        text: 'Доп.город выгрузки'
    },
    {
        key: 105,
        text: 'Шаттл'
    },
    {
        key: 107,
        text: 'Доставка Create FTL (CF)'
    },
    {
        key: 109,
        text: 'Доставка (план/факт)'
    },
    {
        key: 110,
        text: 'Доставка нескольких ГЕ (ЕР)'
    },
    {
        key: 111,
        text: 'Доставка нескольких ГЕ (BХ)'
    },
    {
        key: 113,
        text: 'Доп.точка Create FTL (CF)'
    },
    {
        key: 114,
        text: 'Доп.точка'
    },
    {
        key: 200,
        text: 'Сверхнормативное время работы ТС плановое'
    },
    {
        key: 201,
        text: 'Время работы ТС',
        title: 'Время работы ТС (для доставок по городу-общее время работы ТС, для региональных-время работы ТС на выгрузке),ч.',
    },
    {
        key: 202,
        text: 'ПРР'
    },
    {
        key: 203,
        text: 'Грузчики'
    },
    {
        key: 204,
        text: 'Перепаллетизация'
    },
    {
        key: 205,
        text: 'Перепаллетизация'
    },
    {
        key: 206,
        text: 'Парковка'
    },
    {
        key: 207,
        text: 'ПРР'
    },
    {
        key: 208,
        text: 'Простой Create FTL (CF)'
    },
    {
        key: 209,
        text: 'ПРР Create FTL (CF)'
    },
    {
        key: 210,
        text: 'Экспедирование'
    },
    {
        key: 211,
        text: 'Экспедирование'
    },
    {
        key: 212,
        text: 'Экспедирование'
    },
    {
        key: 213,
        text: 'Термописец'
    },
    {
        key: 214,
        text: 'Коники'
    },
    {
        key: 215,
        text: 'Ремни/Распорки'
    },
    {
        key: 216,
        text: 'Пустые поддоны'
    },
    {
        key: 217,
        text: 'Экспедитор ФМ Create FTL (CF)'
    },
    {
        key: 218,
        text: 'Таможенное оформление'
    },
    {
        key: 220,
        text: 'Прочее'
    },
    {
        key: 221,
        text: 'Подготовка ТСД'
    },
    {
        key: 401,
        text: 'Возврат'
    },
    {
        key: 402,
        text: 'Возврат'
    },
    {
        key: 500,
        text: 'ЖД Доставка'
    },
    {
        key: 505,
        text: 'ЖД. Прочие расходы'
    },
    {
        key: 506,
        text: 'ЖД. Международная доставка'
    },
    {
        key: 601,
        text: 'Доставка пустых поддонов'
    },
    {
        key: 602,
        text: 'Предоставление пустого поддона'
    },
    {
        key: 603,
        text: 'Возврат пустых паллет'
    },
    {
        key: 700,
        text: 'Удаленность от МКАД'
    },
    {
        key: 701,
        text: 'Резерв ТС'
    },
    {
        key: 702,
        text: 'Охрана/Сопровождение груза'
    },
    {
        key: 703,
        text: 'Хранение'
    },
    {
        key: 704,
        text: 'Утилизация'
    },
    {
        key: 705,
        text: 'Внутрисклад Перемещ(FM-FM)'
    },
    {
        key: 706,
        text: 'Доп.Упак. Обрешетка'
    },
    {
        key: 707,
        text: 'Доп.Упак. Короб'
    },
    {
        key: 708,
        text: 'Спецтехника (для выгрузки)'
    },
    {
        key: 709,
        text: 'Скидка'
    },
    {
        key: 710,
        text: 'Пропуск в Город'
    },
    {
        key: 711,
        text: 'Платон'
    },
    {
        key: 712,
        text: 'Въезд в ТТК'
    },
    {
        key: 713,
        text: 'Въезд в Садовое Кольцо'
    },
    {
        key: 717,
        text: 'Архив ТСД'
    },
    {
        key: 721,
        text: 'Страхование грузов'
    },
    {
        key: 722,
        text: 'Страхование сроков'
    },
    {
        key: 730,
        text: 'Дост-ка в нерабВремя+выходные'
    },
    {
        key: 731,
        text: 'Подача ТС к определ. времени'
    },
    {
        key: 732,
        text: 'Загрузка в выходные/праздники'
    },
    {
        key: 733,
        text: 'Разгрузка в выходные/праздники'
    },
    {
        key: 734,
        text: 'Доставка нескольких ГЕ (HP)'
    },
    {
        key: 735,
        text: 'Авизация на выгрузку'
    },
    {
        key: 740,
        text: 'Скоринг (%+/-)'
    },
    {
        key: 741,
        text: 'Негабарит'
    },
    {
        key: 742,
        text: 'Перевес'
    },
    {
        key: 743,
        text: 'Сезонная надбавка (%)'
    },
    {
        key: 800,
        text: 'Претензия'
    },
    {
        key: 900,
        text: 'Международная доставка'
    },
    {
        key: 901,
        text: 'Доставка с НДС 0'
    }
];

const headers2 = [
    {
        key: 'deliveryCostWithoutServicesWithoutVAT',
        text: 'Стоимость доставки без доп. услуг, без НДС',
        sortable: true,
    },
    {
        key: 'costWithoutVAT',
        text: 'Общая стоимость без НДС',
        sortable: true,
    },
    {
        key: 'costWithVAT',
        text: 'Общая стоимость с НДС',
        sortable: true,
    },
];

const headers3 = [
    {
        key: 'clientCostWithoutVAT',
        text: 'Стоимость без НДС: Клиент',
    },
    {
        key: 'clientComment',
        text: 'Комментарий: Клиент',
        sortable: true,
    },
    {
        key: 'fmComment',
        text: 'Комментарий: FM',
        sortable: true,
    },
    {
        key: 'document',
        text: 'Товаросопроводительные документы',
    },
    {
        key: 'state',
        text: 'Статус',
        sortable: true,
    },
];

export {headers1, headersLoadUnits, headers1Ext, headersTrip, headersServices, headers2, headers3};
