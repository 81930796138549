import React from 'react';
import {Form, Input} from 'semantic-ui-react';
import InputDropdown from '../../../components/inputs/InputDropdown';
import T from '../../../components/Translate';
import {CompanyStatusEnum, CompanyStatusOptions} from '../../../api/model/Company';
import SelectFromDictionary from "../../../components/inputs/SelectFromDictionary";
import {DictionariesEnum} from "../../../api/model/Dictionaries";


export default function CompanyForm({data, setData, isNew = false, errors = []}) {

    const update = prop => setData({...data, ...prop});

    return (
        <Form>
            <Form.Group widths="equal">
                <Form.Input
                    error={errors.includes('name')}
                    required
                    label={'Название'.t}
                    placeholder={'Название'.t}
                    value={data.name}
                    onChange={e => update({name: e.target.value})}
                />
                <Form.Input
                    error={errors.includes('inn')}
                    required
                    label={'ИНН клиента'.t}
                    placeholder={'ИНН клиента'.t}
                    value={data.inn || ''}
                    onChange={e => update({inn: e.target.value})}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Input
                    label={'Грузоотправитель'.t}
                    placeholder={'Грузоотправитель'.t}
                    value={data.shipperName}
                    onChange={e => update({shipperName: e.target.value})}
                />
                <Form.Input
                    error={errors.includes('inn')}
                    required
                    label={'ИНН грузоотправителя'.t}
                    placeholder={'ИНН грузоотправителя'.t}
                    value={data.innShipper || ''}
                    onChange={e => update({innShipper: e.target.value})}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field
                    error={errors.includes('fmid')}
                    width={8}>
                    <label className='label-with-start'><T>SearchName</T></label>
                    <Input
                        required
                        disabled={!isNew}
                        placeholder={'SearchName'.t}
                        value={data.fmid}
                        onChange={e => update({ fmid: e.target.value })}
                    />
                </Form.Field>
                <Form.Input
                    error={errors.includes('idSAP')}
                    required
                    label={'SAP'}
                    placeholder={'SAP'.t}
                    value={data.idSAP || ''}
                    onChange={e => update({idSAP: e.target.value})}
                />
                <Form.Input
                    label={'ОГРН'.t}
                    placeholder={'ОГРН'.t}
                    value={data.ogrn || ''}
                    onChange={e => update({ogrn: e.target.value})}
                />

            </Form.Group>

            <Form.Group widths="equal">
                <Form.Input
                    label={'Индекс юр. адреса'.t}
                    placeholder={'Индекс юридического адреса'.t}
                    value={data.regAddressIndex || ''}
                    onChange={e => update({regAddressIndex: e.target.value})}
                />
                <Form.Input
                    label={'Дата регистрации'.t}
                    placeholder={'ДД.ММ.ГГГГ'.t}
                    value={data.regDate || ''}
                    onChange={e => update({regDate: e.target.value})}
                />
            </Form.Group>
            <Form.Input
                label={'Юридический адрес'.t}
                placeholder={'Юридический адрес'.t}
                value={data.regAddress || ''}
                onChange={e => update({regAddress: e.target.value})}
            />
            <Form.Input
                label={'Фактический адрес'.t}
                placeholder={'Фактический адрес'.t}
                value={data.postAddress || ''}
                onChange={e => update({postAddress: e.target.value})}
            />
            <Form.Group>
                <Form.Checkbox
                    label={'Фактический адрес совпадает с юридическим адресом'.t}
                    checked={data.postAddressEqRegAddress}
                    onChange={(e, item) => update({postAddressEqRegAddress: item.checked})}
                />
                <Form.Checkbox
                    className='m-l-15'
                    label={'Автоматическая авизация'.t}
                    checked={data.isAutoAvisationEnabled}
                    onChange={(e, item) => update({isAutoAvisationEnabled: item.checked})}
                />
            </Form.Group>
            <Form.Group>
                <Form.Checkbox
                    label={'Доступ к созданию аукционов'.t}
                    checked={data.canCreateAuction}
                    onChange={(e, item) => update({canCreateAuction: item.checked})}
                />
            </Form.Group>

            {data.fmid && (
                <Form.Field>
                    <label><T>Статус</T></label>
                    <InputDropdown
                        placeholder={"Статус".t}
                        options={CompanyStatusOptions}
                        value={data.statusId}
                        onChange={val => {
                            const data = {statusId: val};
                            if (val === CompanyStatusEnum.ACTIVE) {
                                data.comment = '';
                                data.reasonForBlocking = null;
                            }
                            update(data);
                        }}
                    />
                </Form.Field>
            )}
            {
                data.statusId === CompanyStatusEnum.BLOCKED && <Form.Group widths="equal">
                    <Form.Field
                        width={6}
                    >
                        <label>Причина блокировки</label>
                        <SelectFromDictionary
                            dictionaryKey={DictionariesEnum.REASONS_FOR_BLOCKING}
                            placeholder={'Причина блокировки'.t}
                            value={data.reasonForBlocking}
                            onChange={value => update({reasonForBlocking: value})}
                        />
                    </Form.Field>
                    <Form.Input
                        width={10}
                        label='Комментарий'
                        placeholder='Комментарий'
                        value={data.comment || ''}
                        onChange={e => update({comment: e.target.value})}
                    />
                </Form.Group>
            }
            <Form.Input
                label={'Страна происхождения груза'.t}
                placeholder={'Страна происхождения груза'.t}
                value={data.originCountry || ''}
                onChange={e => update({ originCountry: e.target.value })}
            />
        </Form>
    );

}
