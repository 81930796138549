import React from "react";
import {Container, Label} from "semantic-ui-react";
import {getPermissionName, getRoles, postRole} from "../../../api/usersRoles";
import AppToolbarButton from "../../../components/buttons/AppToolbarButton";
import AppTable from "../../../components/tables/AppTable";
import AppTableHeader from "../../../components/tables/AppTableHeader";
import AppTableToolbar from "../../../components/tables/AppTableToolbar";
import Page from "../../../layout/page/Page";
import T from "../../../components/Translate";
import UserRoleForm from "./UserRoleForm";
import {LinkInfo} from "../../../layout/navbar/Navbar";
import {ContextNavi} from "../../../services/context";
import {fmRelationValue} from "../../../services/values";

const headers = [
    new AppTableHeader("id", "ID"),
    new AppTableHeader("color", "Цвет"),
    new AppTableHeader("fmRelation", "Отношение к FM", 2),
    new AppTableHeader("name", "Название роли"),
    new AppTableHeader("permissions", "Доступы")
];

export default class UsersProfiles extends Page {
    static contextType = ContextNavi;

    componentWillMount() {
        if (this.context.setItems) {
            this.context.setItems([new LinkInfo("Роли пользователей", "/profiles")]);
        }
    }

    fetchData() {
        return getRoles().then(rows => {
            this.setState({rows});
        });
    }

    async addItem(profile) {
        await postRole(profile);
        return this.fetchData();
    }

    async editItem(profile) {
        await postRole(profile);
        return this.fetchData();
    }

    delItem(row) {
        alert("deleting item x");
    }

    renderItem(item, key) {
        const val = item[key];

        switch (key) {
            case "color":
                return <Label circular color={val} empty key={val}/>;

            case "permissions":
                return val.map(v => (
                    <Label key={v} color="blue" className={"label-permissions"}>
                        <T>{getPermissionName(v)}</T>
                    </Label>
                ));

            case "fmRelation":
                return fmRelationValue(val);

            default:
                return val;
        }
    }

    renderData() {
        const {modal} = this.state;

        const editRowAction = (data) => {
            this.setState({
                modal: {
                    open: true,
                    data,
                    isEdit: true,
                    title: "Редактировать".t + " " + data.name,
                    submit: this.editItem.bind(this)
                }
            })
        };

        const handleAddClick = () => {
            this.setState({
                modal: {
                    open: true,
                    title: "Новая роль",
                    submit: this.addItem.bind(this),
                    form: UserRoleForm
                }
            })
        };

        return (
            <div className="bgn-white h-100">
                <UserRoleForm
                    {...modal}
                    title="Новая роль"
                    size="small"
                    onClose={() => this.setState({modal: {open: false}})}
                />
                <Container className="h-100 app-table">
                    <div className="flex-col h-100">
                    <AppTableToolbar>
                        <AppToolbarButton icon="plus" onClick={handleAddClick}>Добавить роль</AppToolbarButton>
                    </AppTableToolbar>
                    <div className="flex-grow scrollable-v">
                        <AppTable
                            headers={headers}
                            rows={this.state.rows}
                            renderItem={this.renderItem.bind(this)}
                            onRowClick={editRowAction}
                        />
                    </div>
                    </div>
                </Container>
            </div>
        );
    }
}
