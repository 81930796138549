import React from 'react';
import SelectCheckboxMulti from "../../../../components/inputs/SelectCheckboxMulti";
import {withRouter} from "react-router-dom";
import UrlFilter from "./models/UrlFilter";
import FilterInputClearButton from "./FilterInputClearButton";
import {getDictionaryItemsData} from "../../../../api/dictionaries";
import O from "../../../../components/Option";


class WbGridFilterListMulti extends React.Component {

    constructor(props){
        super(props);

        const urlFilter = new UrlFilter(this.props.location.search, this.props.field);

        this.state = {
            value: urlFilter !== '' ? urlFilter.value : ''
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.location.search !== this.props.location.search) {
            const urlFilter = new UrlFilter(this.props.location.search, this.props.field);

            this.setState({
                value: urlFilter !== '' ? urlFilter.value : ''
            })
        }

        if (this.props.filters && prevProps.filters !== this.props.filters) this.setState({
            value: this.props.filters[this.props.field]
        })
    }

    handleOptionClick(value) {
        this.setState({value})
    };

    handleClearClick() {
        this.props.removeFilter(this.props.field);
    };

    onCloseSelect() {
        const {value} = this.state;
        this.props.applyFilter({
            [this.props.field]: value
        });
    }

    async getFilterRows() {
        const rows = await getDictionaryItemsData(this.props.dictionaryName, '');
        this.setState({
            optionRows: (rows || []).map(r => new O(r.id, r.name))
        });
    }

    componentDidMount() {
        if (this.props.dictionaryName) {
            this.getFilterRows();
        }
    }

    render() {
        const {options, field, noIcon = false, search = false, hideOnScroll, allValue = null, emptyFilters, dependentOnOthersOptions, position, separator} = this.props;
        const {value, optionRows} = this.state;

        return(

            <div
                className="filter-flex-container"
                style={{backgroundColor: value ? '#ffface' : null}}>
                <div className="ui input filter-flex-center">
                    <SelectCheckboxMulti
                        separator={separator}
                        emptyFilters={emptyFilters}
                        dependentOnOthersOptions={dependentOnOthersOptions}
                        allValue={allValue}
                        hideOnScroll={hideOnScroll}
                        search={search}
                        noIcon={noIcon}
                        value={value}
                        onChange={this.handleOptionClick.bind(this)}
                        options={optionRows || options || []}
                        name={field}
                        onClose={this.onCloseSelect.bind(this)}
                        position={position}
                    />
                </div>
                <div className={value ? "filter-flex-right filter-flex-right-active" : "filter-flex-right"}>
                    {value && <FilterInputClearButton
                        handleInputClearClick={this.handleClearClick.bind(this)}/>}
                </div>
            </div>
        )
    }
}

export default withRouter(WbGridFilterListMulti)
