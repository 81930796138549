import React from 'react';
import {Divider, Form, Grid, GridColumn, GridRow} from "semantic-ui-react";
import {PickupTypeOptions} from "../../../../../api/model/Enums";


const columns = [
    {
        name: 'Заявка',
        key: 'id'
    },
    {
        name: 'Тип сервиса доставки',
        key: 'serviceType'
    },
    {
        name: 'Грузоотправитель',
        key: 'consignor'
    },
    {
        name: 'Адрес загрузки',
        key: 'loadingAddress'
    },
    {
        name: 'Грузополучатель',
        key: 'consignee'
    },
    {
        name: 'Адрес разгрузки',
        key: 'unloadingAddress'
    },
    {
        name: 'Способ доставки',
        key: 'pickupType',
        options: PickupTypeOptions,
        placeholder: 'Способ доставки груза'
    },
];

const widthsWithSelect = [2, 2, 2, 2, 2, 2, 4];
const widthsWithoutSelect = [2, 2, 3, 3, 3, 3];

const Table = ({columns, rows, setValue, widths}) => {
    return <Grid verticalAlign="middle" divided="vertically">
        <GridRow className="p-b-0">
            {
                columns.map(({name, key}, i) => <GridColumn
                    key={key}
                    width={(widths || [])[i]}
                >
                    {name}
                </GridColumn>)
            }
        </GridRow>
        {(rows || []).map(r =>
            <GridRow className="p-b-0 p-t-0">
                {
                    columns.map(({key, options, placeholder}, i) => <GridColumn
                        key={key}
                        width={(widths || [])[i]}
                    >
                        {
                            !options
                                ? r[key]
                                : <Form.Select
                                    fluid
                                    options={options}
                                    placeholder={placeholder}
                                    value={r[key]}
                                    onChange={(e, {value}) => setValue(r.id, value)}
                                />
                        }
                    </GridColumn>)
                }
            </GridRow>
        )}
    </Grid>;
};

const Tables = ({rows, setValue, open, allIsNoHub}) => {
    const withoutSelectRows = allIsNoHub ? rows : (rows || []).filter(r => !r.isHub);
    const withSelectRows = !allIsNoHub &&  (rows || []).filter(r => r.isHub);

    return (
        <>
            {(open && !allIsNoHub) && <div className="m-t-15 scrollable">
                <Divider/>
                <Table
                    rows={withSelectRows}
                    setValue={setValue}
                    columns={columns}
                    widths={widthsWithSelect}
                />
            </div>}
            {(allIsNoHub || !!(withoutSelectRows || []).length) && <div className={`m-t-15 ${!allIsNoHub ? 'p-t-15' : ''} scrollable`}>
                {!allIsNoHub && <b>Также будут отправлены заявки</b>}
                <Divider/>
                <Table
                    rows={withoutSelectRows}
                    columns={columns.slice(0, -1)}
                    widths={!allIsNoHub ? widthsWithSelect : widthsWithoutSelect}
                />
            </div>}
        </>
    );
};

export default Tables;