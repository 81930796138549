import React, {useContext, useEffect, useState} from 'react';
import {LinkInfo} from "../../layout/navbar/Navbar";
import {ContextNavi} from "../../services/context";
import Shield from "../../layout/page/shield/Shield";
import {Divider, Dropdown, Form} from "semantic-ui-react";
import {getDictionaryTypes} from "../../api/dictionaries";
import DictionariesTable from "./table";

const DictionariesList = ({location, history}) => {
    const contextNavi = useContext(ContextNavi);

    const [loading, setLoading] = useState(false);
    const [dictionary, setDictionary] = useState(null);
    const [dictionaries, setDictionaries] = useState([]);

    useEffect(() => {
        contextNavi.setItems([new LinkInfo("Справочники", "/dictionaries")]);

        let cleanupFunction = false;
        const loadData = async () => {
            setLoading(true);
            try {
                await getDictionaryTypes().then(dts => {
                    setDictionaries(dts);
                    setDictionary(dts[0].value);
                });

            } catch (e) {
                console.error(e.message);
            }
        };
        loadData().then(() => setLoading(false));
        return () => cleanupFunction = true;
    }, []);


    return (
        <Shield loading={loading} loadingOver>
            <div className='dictionaries'>
                <b>Редактируемый справочник</b>
                <Divider />
                <Form.Field>
                    <Dropdown
                        className="InputDropdown dictionaries__input"
                        selection
                        name='dictionary'
                        value={dictionary}
                        onChange={(e, { value })=> {
                            setDictionary(value);
                        }}
                        fluid
                        closeOnChange
                        options={dictionaries}
                        selectOnBlur={false}
                    />
                </Form.Field>

                <b>Состав справочника</b>
                <Divider />
                <DictionariesTable dictionary={dictionary} setLoading={setLoading}/>
            </div>
        </Shield>
    );
};

export default DictionariesList;
