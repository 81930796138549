import React from 'react';
import {Form} from "semantic-ui-react";

const MaskInput = ({value, name, error, onChange, placeholder = '', label, required, regex, onBlur, fluid, noLabel, icon}) => {
    const handleChange = (e) => {
        const newValue = e.target.value || '';

        if (regex.test(newValue) || !newValue) {
            onChange({[name]: newValue});
        }
    };

    return (
        <Form.Input
            id={`input_${name}`}
            required={required}
            error={error}
            label={!noLabel ? (label || name) : null}
            placeholder={placeholder}
            value={value || ''}
            onChange={handleChange}
            onBlur={onBlur}
            fluid={fluid}
            icon={icon}
        />
    );
};

export default MaskInput;