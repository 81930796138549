export const keys = [
    {
        ru: "0 - Не экспортирован, 1 - Эспортирован, 2 - Ошибка экспорта",
        en: "0 - not exported, 1 - exported, 2 - export failed"
    },
    { ru: "24 часа", en: "24 hours" },
    { ru: "Не зарегистрирован", en: "Not registered" },
    { ru: "Зарегистрирован", en: "Registered" },
    { ru: "Ошибка регистрации", en: "Registration error" },
    { ru: "[не задано]", en: "[not specified]" },
    { ru: "1,5 т", en: "1.5 tons" },
    { ru: "3 т", en: "3 tons" },
    { ru: "5 т", en: "5 tons" },
    { ru: "10 т", en: "10 tons" },
    { ru: "20 т", en: "20 tons" },

    { ru: "Новая заявка", en: "New" },
    { ru: "Номер заявки FM", en: "Shipping #" },
    { ru: "Количество пустых поддонов", en: "Empty palettes count" },
    { ru: "пустых поддонов", en: "Empty palettes" },
    { ru: "Стоимость груза без НДС", en: "Cargo cost excl. VAT" },
    { ru: "Данные о грузе", en: "Cargo Schedule" },
    { ru: "Загрузка выгрузка", en: "Cargo Points" },
    { ru: "Далее", en: "Next" },
    { ru: "Добавить позицию", en: "Add Cargo Position" },

    { ru: "FTL доставка", en: "FTL Shipping" },
    { ru: "LTL доставка", en: "LTL Shipping" },


    { ru: "Авизировать на web-booking", en: "Web-booking Dock Appointment" },
    { ru: "Адрес", en: "Address" },
    { ru: "Адреса", en: "Addresses" },
    { ru: "Адрес доставки", en: "Shipping Address" },
    { ru: "Адрес отправления", en: "Pickup Address" },
    { ru: "Адрес загрузки", en: "Pickup Address" },
    { ru: "Активировать", en: "Verify" },
    { ru: "Архивировать адрес?", en: "Archive the address?" },
    { ru: "Адреса и грузы", en: "Addresses and cargo" },
    { ru: "Адрес отправления (требуется указать адрес до улицы)", en: "Pickup Address (street name including)" },

    { ru: "больше", en: "more than" },

    { ru: "ввести", en: "enter" },
    { ru: "Время", en: "Time" },
    { ru: "Вес отправки брутто", en: "Weight, brutto" },
    { ru: "Войти", en: "Log in" },
    { ru: "вт", en: "tue" },
    { ru: "вс", en: "sun" },
    { ru: 'Вход в клиентский портал', en: 'Client Portal Login' },
    { ru: "Выгрузка", en: "Unload" },
    {
        ru: "введите значения (0 - Не экспортирован, 1 - Эспортирован, 2 - Ошибка экспорта)",
        en: "enter value (0 - not exported, 1 - exported, 2 - export failed)"
    },
    { ru: "Введите не менее 3 символов для поиска", en: "at least 3 characters" },
    { ru: "Введите 3 или более букв для поиска", en: "Enter 3 or more letters to search"},
    { ru: "Вес", en: "Weight" },
    { ru: "Введенный адрес некорректен", en: "Incorrect address" },
    { ru: "Введите адрес по частям: улица, номер дома и выберите из списка", en: "Start typing your address and select from the list" },
    { ru: "Введите адрес по частям | название клиента | название для быстрого поиска | fmid", en: "Enter the address in parts | company name | name for quick search | fmid"},
    { ru: "Введите адрес по частям | название клиента | название для быстрого поиска", en: "Enter the address in parts | company name | name for quick search"},
    { ru: "грузовых ед.,", en: "units," },
    { ru: "Грузоподъемность", en: "Carrying capacity" },

    { ru: "Дата", en: "Date" },
    { ru: "Дата загрузки", en: "Shipping Date" },
    { ru: "Дата и время загрузки", en: "Shipping Date" },
    { ru: "Дата доставки", en: "Delivery Date" },
    { ru: "Дата и время доставки", en: "Delivery Date" },
    { ru: "Дата регистрации", en: "Registration Date" },
    { ru: "Дата прибытия", en: "Arrival date" },
    { ru: "Добавить", en: "Add" },
    { ru: "Добавить клиента", en: "New Company" },
    { ru: "Добавить поставщика", en: "New Provider" },
    { ru: "Добавить роль", en: "New Role" },
    { ru: "Добавить пользователя", en: "New User" },
    { ru: "Документы", en: "Attachments" },
    { ru: "Доступы", en: "Permissions" },
    { ru: "Доставка во временной слот", en: "Timed delivery" },
    { ru: "Доставка в нерабочее время", en: "Weekend delivery" },
    { ru: "ДД.ММ.ГГГГ", en: "dd.mm.yyyy" },
    { ru: "Дополнительные параметры", en: "Additional parameters"},


    { ru: "Грузополучатель", en: "Consignee" },
    { ru: "Грузоотправитель", en: "Shipper" },
    { ru: "Грузоподъемность", en: "Load weight capacity" },
    { ru: "Грузоподъемность изменена на", en: "Carriyng capacity set to" },

    { ru: "Импорт Заявок", en: "Import Excel" },
    { ru: "Исполнение", en: "Progress" },
    { ru: "История", en: "History" },
    { ru: "Информация о грузе", en: "Load Info" },

    { ru: "Загрузка-выгрузка", en: "Load Dock" },
    { ru: "боковая", en: "from side" },
    { ru: "задняя", en: "at the rear" },

    { ru: "Загрузка", en: "Load" },
    { ru: "Забыли пароль?", en: "Forgot password?" },
    { ru: "Заявки", en: "Waybills" },
    { ru: "Заявка", en: "Waybill" },
    { ru: "Зарегистрировать", en: "Register" },
    { ru: "Заменить", en: "Replace"},
    { ru: "Заменить на существующий", en: "Replace with an existing one" },
    { ru: "записей", en: "records" },
    {
        ru: "Заданные позиции на загрузке не соответствуют позициям на выгрузке",
        en: "The positions in load and unload doesn't much"
    },
    {
        ru: "Дата/время на загрузке/выгрузке заполнены некорректно",
        en: "Date / time on loading / unloading is filled incorrectly"
    },
    { ru: "Зона по МСК", en: "Zone (Moscow only)" },
    { ru: "закрыто", en: "closed" },

    { ru: "ИНН", en: "INN" },
    { ru: "Имя", en: "First Name" },
    { ru: "Источник", en: "Source" },
    { ru: "Импорт адресов", en: "Import addresses" },
    { ru: "Интетификатор не получен", en: "Can't get an id" },
    { ru: "импорт заявок в формате Excel", en: "import Excel" },
    { ru: "Индекс", en: "Zipcode" },
    { ru: "Использовать один из существующих адресов", en: "You can use one of the existing addresses" },

    { ru: "Клиент", en: "Client" },
    { ru: "Количество грузовых единиц", en: "Load items count" },
    { ru: "Клиенты", en: "Companies" },
    { ru: "Клиент", en: "Company" },
    { ru: "Комментарий", en: "Comments" },
    { ru: "комментарий", en: "comments" },
    { ru: "Контакты и опции", en: "Options and Contacts" },
    { ru: "Контакт", en: "Contact" },
    { ru: "Контрагент", en: "Consignee" },
    { ru: "Контактное лицо", en: "Contact" },
    { ru: "Контактное лицо на загрузке", en: "Contact at Pickup" },
    { ru: "Контактное лицо на доставке", en: "Contact at Destination" },
    { ru: "Круглосуточно", en: "24 hours" },
    { ru: "кг", en: "kg" },
    { ru: "Краткое название (search name)", en: "Short name (search name)" },

    { ru: "Локация", en: "Location"},

    { ru: "меньше", en: "less than" },
    { ru: "маршрут", en: "route steps" },

    { ru: "Название", en: "Name" },
    { ru: "Название клиента", en: "Company Name" },
    { ru: "Название роли", en: "Role Name" },
    { ru: "Номер заявки", en: "Shipping #" },
    { ru: "Номер заказов", en: "Waybills No" },
    { ru: "Номера SSCC", en: "SSCC No" },
    { ru: "Новый клиент", en: "New Company" },
    { ru: "Новая точка", en: "New Point" },
    { ru: "Новая роль", en: "New Role" },
    { ru: "Новый пользователь", en: "New User" },
    { ru: "Новый адрес", en: "New address" },
    { ru: "не равно", en: "not equal to" },
    { ru: "Название в системе FM", en: "Name in FM" },
    { ru: "Название адреса в системе FM", en: "Name address in FM" },
    { ru: "Название в системе клиента", en: "Name in the client's system" },
    { ru: "Неизвестная ошибка", en: "Unknown error" },
    { ru: "На корректировку", en: "Request correction" },
    { ru: "Не заполнено поле", en: "Not filled" },
    { ru: "Начать новую заявку", en: "Create new application" },
    { ru: "На исполнение", en: "Execute" },
    { ru: "Населенный пункт", en: "Locality" },
    { ru: "Населенный пункт (с типом)", en: "Locality (type including)" },
    { ru: "Название улицы (с типом)", en: "Street name (type including)" },
    { ru: "Название улицы включая тип", en: "Street name (type including)" },
    { ru: "Номер дома (с типом)", en: "Street no" },
    { ru: "Номер строения (с типом)", en: "Building" },
    { ru: "Название для быстрого поиска", en: "Name for quick search" },
    { ru: "fmid адреса", en: "fmid address"},
    { ru: "Fmid адреса", en: "Fmid address"},
    { ru: "Название клиента (с формой собственности)", en: "Company name (type including)" },
    { ru: "Найдите или введите название клиента", en: "Find a company or enter your own" },
    { ru: "например д 1 или влд 3", en: "Street no" },
    { ru: "например стр 1 или корпус 8", en: "Building" },
    { ru: "Назад", en: "Back" },

    { ru: "Обрешетка", en: "Packing" },
    { ru: "Основная информация", en: "Main Information" },
    { ru: "ОГРН", en: "OGRN" },
    { ru: "Особые примечания", en: "Comments" },
    { ru: "Объем, м3", en: "Volume, cubic meters" },
    { ru: "Отклонить", en: "Decline" },
    { ru: "От", en: "From" },
    { ru: "Офис", en: "Office" },
    { ru: "Офис или квартира", en: "Office or flat" },

    { ru: "Ошибка создания FTL", en: "FTL creating error" },

    { ru: "Отсутствуют точка(и) загрузки/выгрузки", en: "No load/unload address provided" },
    { ru: "Отсутствует информация о дате/времени загрузки", en: "No load date/time provided" },
    { ru: "Отсутствует информация о грузовых единицах в точке(ах) загрузки", en: "No units info provided" },
    { ru: "Отсутствует информация о дате выгрузки", en: "No unload date provided" },
    { ru: "Отсутствует информация о номерах заказов в точке(ах) выгрузки", en: "No order number provided for unload points"},
    { ru: "Отсутствует информация о выбранных сервисах выгрузки", en: "There is no information about the selected upload services"},
    { ru: "Отсутствует информация о выбранных сервисах загрузки", en: "Missing information on selected download services"},
    { ru: "Не указано значение по доп. услугам", en: "Not specified value for add. services"},
    {
        ru: "Грузоподъемность транспортного средства не подходит под весовые показатели груза",
        en: "Vehicle's carriyng capacity is not enough for the specified cargo"
    },
    { ru: "Отправить в FM", en: "Send to FM" },

    { ru: "Пароль", en: "Password" },
    { ru: "Первичные документы", en: "Opening documents" },
    { ru: "Подтверждающие документы", en: "Closing documents" },
    { ru: "Плановый простой", en: "Planned delay" },
    { ru: "по", en: "to" },
    { ru: "пн", en: "mon" },
    { ru: "пт", en: "fri" },
    { ru: "Подтвердить", en: "Confirm" },
    { ru: "Поиск по всем полям...", en: "Search any field..." },
    { ru: "Пользователи", en: "Users" },
    { ru: "Пользователей", en: "Users Count" },
    { ru: "ПРР (силами водителя)", en: "Load mount/unmount" },
    { ru: "Примечание", en: "Comment" },
    { ru: "Привязка к клиентам", en: "User Companies" },
    { ru: "Привязать все клиенты", en: "Link all companies"},
    { ru: "Все клиенты", en: "All companies"},
    { ru: "Поставщик", en: "Provider" },
    { ru: "Произошла ошибка. Обратитесь к администратору сайта", en: "Error occured. Please contact support" },
    { ru: "проверить и отправить выбранные заявки на исполнение", en: "verify and execute" },
    { ru: "Похожий адрес уже существует. Вы точно хотите продолжить?", en: "You can use one of the existing addresses" },
    { ru: "Пропустить и продолжить", en: "Skip and continue" },
    { ru: "Похожие адреса уже существуют. Вы точно хотите продолжить?", en: "You can use one of the existing addresses" },

    { ru: "Рабочие дни", en: "Work days" },
    { ru: "равно", en: "equal to" },
    { ru: "Редактировать", en: "Edit" },
    { ru: "редактировать роль", en: "edit role" },
    { ru: "Регистрация", en: "Registration" },
    { ru: "Роль", en: "Role" },
    { ru: "Роли", en: "Roles" },
    { ru: "Роли пользователей", en: "Users Roles" },
    { ru: "рассчитать", en: "calculate" },
    { ru: "Регион", en: "Region" },
    { ru: "Район", en: "Disctrict" },

    { ru: "с", en: "from" },
    { ru: "ср", en: "wed" },
    { ru: "сб", en: "sat" },
    { ru: "Сбросить пароль", en: "Reset Password" },
    { ru: "Склады", en: "Warehouses" },
    { ru: "Склад клиента", en: "Client warehouse" },
    { ru: "Статус", en: "Status" },
    { ru: "Статус заказа", en: "Waybill status" },
    { ru: "Статус перевозки", en: "Shipment status" },
    { ru: "Статус экспорта", en: "Export status" },
    { ru: "Статус регистрации", en: "Registration status" },
    { ru: "Страна", en: "Country" },
    { ru: "Создать Pickup", en: "Create Pickup" },
    { ru: "Создать заявку", en: "Create Shipping" },
    { ru: "Сохранить", en: "Save" },
    { ru: "Мои заявки", en: "Waybills" },
    { ru: "Стоимость доставки", en: "Shipping Cost" },
    { ru: "Связь с сервером потеряна, проверьте соединение", en: "No internet, check you network connection" },
    { ru: "Создать копию заявки", en: "Crate a copy" },
    { ru: "cоздать FTL заявку из выбранных LTL заявок на хаб FM", en: "create an FTL from the selected LTLs" },
    { ru: "Сохранить и зарегистрировать в FM", en: "Save and export to FM" },

    { ru: "Тип заявки", en: "Shipping Type" },
    { ru: "Тип доставки", en: "Shipping Type" },
    { ru: "Тип груза", en: "Load Type" },
    { ru: "Тип грузовой единицы", en: "Load Item Type" },
    { ru: "Номер заказа грузополучателя", en: "Consignee order No" },
    { ru: "Номер заказа грузоотправителя", en: "Shipper order No" },
    { ru: "Номер ТОРГ-12", en: "TORG-12 No" },
    { ru: "Точек", en: "Addresses Count" },
    { ru: "Телефон", en: "Tel No" },
    { ru: "Температурный режим", en: "Thermo Mode" },
    { ru: "Тип", en: "Type" },

    { ru: "Часы работы с", en: "Work hours start" },
    { ru: "Часы работы по", en: "Work hours end" },
    { ru: "часы работы", en: "work hours" },
    { ru: "Часы работы", en: "Work hours" },
    { ru: "чт", en: "thu" },
    { ru: "Черновик", en: "Draft" },

    { ru: "Цвет", en: "Color" },

    { ru: "Экспорт", en: "Export" },
    { ru: "Экспорт Заявок", en: "Export Excel" },
    { ru: "Экспортировать", en: "Export" },
    { ru: "экспорт выбранных заявок в формате Excel", en: "export Excel" },

    { ru: "Юридический адрес", en: "Legal Address" },

    { ru: "Юр.наименования поставщика", en: "Legal Name Provider" },
    { ru: "Юр.наименование поставщика", en: "Legal Name Provider" },

    { ru: "Фамилия", en: "Last Name" },
    { ru: "ФИО", en: "Name" },
    { ru: "Фактический адрес совпадает с юридическим адресом", en: "Legal address and Post address are the same" },
    { ru: "Фактический адрес", en: "Post address" },
    { ru: "все", en: "all"},
    { ru: "выбрано", en: "selected"},
    { ru: "Удалить ошибочные", en: "Delete erroneous" },
    { ru: "Заполните обязательные поля", en: "Fill in required fields"},
    { ru: "Найти/Cоздать мой адрес", en: "Find / Create my address"},
    { ru: "Результатов не найдено", en: "No results found"},
    { ru: "Выберите цвет", en: "Choose a color"},
    { ru: "Выберите роль", en: "Choose a role"},
    { ru: "Выберите доступы", en: "Select Accesses"},
    { ru: "Авизация для", en: "Avization for"},
    { ru: "заявки", en: "applications"},
    { ru: "заявок", en: "applications"},
    { ru: "Ваши заявки содержат", en: "Your applications contain"},
    { ru: "паллет", en: "pallet"},
    { ru: "Тип транспорта", en: "Type of transport"},
    { ru: "Водитель", en: "driver"},
    { ru: "и", en: "and"},
    { ru: "ТС", en: "TS"},
    { ru: "Выбрать время авизации", en: "Choose the time of avization"},
    { ru: "Таймслот", en: "Timeslot"},
    { ru: "Заполните таймслот", en: "Fill in the timeslot"},
    { ru: "Заполните дату привоза", en: "Fill in the date of arrival"},
    { ru: "Дата привоза", en: "Date of arrival"},
    { ru: "Заполните количество паллет для коробов", en: "Fill the number of pallets for boxesl"},
    { ru: "Удалить ошибочные", en: "Delete erroneous" },
    { ru: "Изменить способ доставки", en: "Change shipping method"},
    { ru: "Заявка готова к отправке", en: "Application ready to send"},
    { ru: "Можно изменить способ доставки и отправить", en: "You can change the shipping method and send"},
    { ru: "Невозможно авизовать заявку, так как дата привоза на ФМ в загрузке меньше текущей", en: "It is not possible to recommend the application, since the delivery date in FM when downloading is less than the current"}
];
