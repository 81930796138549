import React, {useEffect, useState} from "react";
import {Popup, Checkbox, Icon, Form, Input} from "semantic-ui-react";

const SelectCheckboxMulti = ({ value, onChange, options: defaultOptions, name, onClose, noIcon, search, hideOnScroll = true, allValue, emptyFilters = null, dependentOnOthersOptions = null, position='bottom left', separator = ',' }) => {
    const minNumCharacters = 2;

    let values = value
        ? value.split(separator)
        : [];

    const toggle = (e, { value }) => {
        if (values.some(x => `${x}` === `${value}`)) {
            values.splice(values.indexOf(`${value}`), 1);
        } else {
            values.push(value);
        }
        onChange && onChange(values.join(separator));
    };

    const [searchValue, setSearchValue] = useState('');
    const [options, setOptions] = useState(defaultOptions);

    useEffect(()=>{
        if (!dependentOnOthersOptions || searchValue.length > minNumCharacters){
            const newOptions = ((dependentOnOthersOptions && !emptyFilters) ? optionsNew() : defaultOptions).filter(o => o.text.toUpperCase().includes(searchValue.toUpperCase()));
            setOptions(newOptions);
        } else setOptionsByValues();
    }, [searchValue]);

    const setOptionsByValues = () => {
        let optionKeys = values;
        let optionsNew = defaultOptions.filter(o => optionKeys.includes(o.key.toString()));
        setOptions(optionsNew);
    };

    const optionsNew = () => {
        let optionKeys = [...new Set([...values, ...dependentOnOthersOptions.map(v=>v.toString())])];
        return defaultOptions.filter(o => optionKeys.includes(o.key.toString()));
    };

    useEffect(() => {
            if (dependentOnOthersOptions){
                setOptionsByValues();
            } else setOptions(defaultOptions);
        }
        , [emptyFilters, dependentOnOthersOptions, defaultOptions]);

    const toggleALL = () => {
        onChange(value.length ? '' : [...options.map(item => item.value)].join(separator));
    };

    const content = (
        <Form>
            {
                search && <Input
                    style={{marginBottom: "10px", width: '100%'}}
                    icon='search'
                    placeholder={'Найти'}
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                />
            }
            <div style={{overflowY: "auto", overflowX: "hidden", maxHeight: "400px"}}>
                {options.length && allValue ? <Form.Field>
                    <Checkbox checked={values.length ? values.length === options.length : false} onChange={toggleALL}
                              label={allValue}/>
                </Form.Field> : null}
                {options.map((x, index) => {
                    let label = (
                        <label>
                            {!noIcon && <Icon color={x.color} inverted={x.inverted} name={x.icon || "circle"}/>}
                            {x.text}
                        </label>
                    );
                    return (<Form.Field key={'item-'+index}>
                        <Checkbox key={'item-'+index} value={x.key} checked={values.includes(`${x.key}`)} onChange={toggle} label={label} />
                    </Form.Field>);
                })}
                {!options.length && search && <label>
                    {!(dependentOnOthersOptions && searchValue.length <= minNumCharacters)
                        ? 'Результатов не найдено'
                        : 'Введите не менее 3-х символов для поиска'}
                </label>}
            </div>

        </Form>
    );

    const onCloseFilter = () => {
        onClose();
        setSearchValue('');
    };

    return (
        <div className="facet-sortable facet-input">
            <Popup
                trigger={
                    <input
                        id={`filter-input-${name}`}
                        className="filter-date-input filter-select"
                        style={ { backgroundColor: value ? '#ffface' : null} }
                        type="text"
                        readOnly
                        value={ values.length > 0
                            ? values.length + " " + "выбрано".t
                            : "все".t}
                    />
                }
                content={content}
                on='click'
                hideOnScroll={hideOnScroll}
                position={position}
                onClose={onCloseFilter}
            />
        </div >
    );

};
export default SelectCheckboxMulti;
