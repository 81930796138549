import React, {useEffect, useState} from 'react';
import './style.css';
import {Checkbox, Confirm, Icon, Label, Menu, Table} from "semantic-ui-react";
import T from "../../components/Translate";
import {
    createDictionaryItem,
    deleteDictionaryItem,
    exportDictionary,
    getClientNames,
    getCompanies,
    getCounties,
    getDictionaryItemsData,
    getPointFMIDsFromCities,
    getRegionsFromCities,
    getTimezones,
    importDictionary,
    updateDictionaryItem
} from "../../api/dictionaries";
import WbGridFilterStringContains from "../waybills/wbGrid/wbGridFilters/WbGridFilterStringContains";
import EditModal from "./editModal";
import {BooleanOptions, DictionariesEnum, DictionariesSettings} from "../../api/model/Dictionaries";
import qs from "query-string";
import WbGridFilterListMulti from "../waybills/wbGrid/wbGridFilters/WbGridFilterListMulti";
import {Oc} from "../../api/model/Waybill";
import EditRow from "./editRow";
import {toast} from "../../services/toast";
import WbGridFilterList from "../waybills/wbGrid/wbGridFilters/WbGridFilterList";
import FormImport from "../../components/formImport/FormImport";
import {isBoolValue} from "../../services/utils";
import {checkRequiredFields, isFullCopy} from "../../services/dictionaries";

const DictionariesTable = ({dictionary, customDeletedText, setLoading, menuItems, customBtns, style, customActions, preFilters, setError, rowOnClick, customCheckError, errorFlag, onErrorFlag, isOnlyView, customCheckRequired, exampleBtnText, exampleFile}) => {

    const [sort, setSort] = useState({
        sortBy: null,
        sortDir: 0
    });

    const [filters, setFilters] = useState(preFilters || {});
    const [filtersData, setFiltersData] = useState(null);
    const [deleteRow, setDeleteRow] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [id, setId] = useState(null);
    const [rows, setRows] = useState([]);
    const {sortBy, sortDir} = sort;

    const [regions, setRegions] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [countries, setCountries] = useState([]);
    const [timezones, setTimezones] = useState([]);
    const [clientNames, setClientNames] = useState([]);
    const [pointFMIDs, setPointFMIDs] = useState([]);
    const [rowNames, setRowNames] = useState([]);

    const dictionarySettings = DictionariesSettings.find(h => h.key.toString() === dictionary) || {};

    const getHeaders = () => {
        return dictionarySettings.headers || [];
    };

    const dataForAllFilters = async (headers = []) => {
        const keys = headers.filter(h => ['checkboxes', 'select'].includes(h.filterType)).map(h => h.key);
        if (!keys.length) return;
        const rows = await getDictionaryItemsData(dictionarySettings.urlName, preFilters ? '?' + qs.stringify(preFilters) : '');
        let fData = {};
        keys.forEach(key => {
            fData[key] = [...(new Set(rows.map(r => r[key]).flat()))].filter(f => f).map(f => `${f}`).sort();
        });
        setFiltersData(fData);
    };

    const getRequest = async (filters) => {
        await dictionarySettings.urlName && getDictionaryItemsData(dictionarySettings.urlName, !filters ? '' : '?' + qs.stringify(filters)).then(res => {
            setRows(res);
            if (errorFlag && onErrorFlag) {
                onErrorFlag(!!res.find(r => r[errorFlag]));
            }
            setLoading(false);
        }).catch(() => {
            setRows([]);
            setLoading(false);
        });
    };

    useEffect(() => {
        setLoading(true);
        setFilters(preFilters || {});
        setSort({
            sortBy: null,
            sortDir: 0
        });
        if (dictionary) {
            updateFilterLists(dictionary);
        }
        setNewRows([]);
        setEditRows([]);
        setGreenRows([]);
        setFiltersData(null);
    }, [dictionary]);

    const updateFilterLists = (dictionary) => {
        if (dictionary.toString() === DictionariesEnum.NETWORKS) {
            getCompanies().then(rs => setCompanies(rs));
            getPointFMIDsFromCities().then(rs => setPointFMIDs(rs));
        }
        dictionary.toString() === DictionariesEnum.CITIES && getRegionsFromCities().then(rs => setRegions(rs));
        if (dictionary.toString() === DictionariesEnum.REGIONS) {
            getCounties().then(rs => setCountries(rs));
            getTimezones().then(rs => setTimezones(rs));
        }
        if (dictionary.toString() === DictionariesEnum.AVISATION) {
            getClientNames().then(cns => setClientNames(cns));
        }
        !dictionarySettings.isNewType && getDictionaryItemsData(dictionarySettings.urlName, '').then(rs => setRowNames(rs.map(r => r.name))).catch(() => setRowNames([]));
        dictionarySettings.isNewType && dataForAllFilters(getHeaders());
    };

    useEffect(() => {
        setLoading(true);
        getRequest({...filters, sortBy, sortDir});
    }, [filters, sortBy, sortDir]);

    const noRows = (
        <div className="no-rows-wrapper">
            <div className="no-rows">Нет данных.</div>
        </div>
    );

    const handleSort = clickedColumn => () => {
        let {sortBy, sortDir} = sort;

        if (sortBy !== clickedColumn) {
            sortBy = clickedColumn;
            sortDir = '0';
        } else {
            sortDir = sortDir === '0' ? '1' : '0';
        }

        setSort({sortBy, sortDir});
    };

    const styleDisabledHeader = {
        background: '#f9fafb',
        cursor: 'default'
    };

    function applyFilter(filter) {
        setFilters(filters => {
            return {...filters, ...filter};
        });
    }

    function removeFilter(key) {
        setFilters(filters => {
            let newFilters = {...filters};
            delete newFilters[key];
            return {...newFilters};
        });
    }

    const getFilter = (key, filterType, items, separator) => {
        switch (filterType || key) {
            case 'name':
                return ![DictionariesEnum.CITIES, DictionariesEnum.CAR_TYPES].includes(dictionary.toString()) ?
                    <WbGridFilterListMulti
                        filters={filters}
                        hideOnScroll={false}
                        search
                        noIcon
                        field={"names"}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                        options={rowNames.filter(s => s).map(r => new Oc(r, r))}/>
                    : <WbGridFilterStringContains
                        field={[DictionariesEnum.CAR_TYPES].includes(dictionary.toString()) ? key : 'search'}
                        applyFilter={applyFilter}
                        removeFilter={removeFilter}
                    />;
            case 'number':
                return <WbGridFilterStringContains
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'text':
                return <WbGridFilterStringContains
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'time':
                return <WbGridFilterStringContains
                    isTime
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'allClientAccess':
            case 'isAuctionAccess':
            case 'isActive':
                return <WbGridFilterList
                    value={filters[key]}
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={BooleanOptions}
                />;
            case 'select':
                return <WbGridFilterList
                    value={filters[key]}
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={((filtersData || {})[key] || []).map(r => new Oc(r, r))}
                />;
            case 'enum':
                return <WbGridFilterList
                    value={filters[key]}
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={items || []}
                />;
            case 'postalCodes':
                return <WbGridFilterStringContains
                    field={'searchIndex'}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'pointFMIDs':
                return <WbGridFilterListMulti
                    filters={filters}
                    hideOnScroll={false}
                    search
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={pointFMIDs.filter(s => s).map(r => new Oc(r, r))}/>;
            case 'checkboxes':
                return <WbGridFilterListMulti
                    separator={separator}
                    filters={filters}
                    hideOnScroll={false}
                    search
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={((filtersData || {})[key] || []).map(r => new Oc(r, r))}/>;
            case 'bodyType':
            case 'waybillTypes':
                return <WbGridFilterListMulti
                    filters={filters}
                    hideOnScroll={false}
                    search
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={getHeaders().find(h => h.key === key).items}/>;
            case 'companyNames':
                return <WbGridFilterListMulti
                    filters={filters}
                    hideOnScroll={false}
                    search
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={companies.map(r => new Oc(r, r))}/>;
            case 'region':
                return <WbGridFilterListMulti
                    filters={filters}
                    hideOnScroll={false}
                    search
                    noIcon
                    field={'regionIds'}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={regions.map(r => new Oc(r.id, r.name))}/>;
            case 'country':
                return <WbGridFilterListMulti
                    filters={filters}
                    hideOnScroll={false}
                    search
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={countries.filter(s => s).map(r => new Oc(r, r))}/>;
            case 'timezone':
                return <WbGridFilterListMulti
                    filters={filters}
                    hideOnScroll={false}
                    search
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={timezones.filter(s => s).map(r => new Oc(r, r))}/>;
            case 'clientNames':
                return <WbGridFilterListMulti
                    filters={filters}
                    hideOnScroll={false}
                    search
                    noIcon
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={clientNames.filter(s => s).map(r => new Oc(r, r))}/>;
            default:
                return null;
        }
    };

    const getCell = (key, val) => {
        switch (key) {
            case 'region':
                return <div>{val && val.name}</div>;
            case 'name':
                return val;
            case 'postalCodes':
            case 'pointFMIDs':
            case 'companyNames':
            case 'clientNames':
                return val && val.map(v => (
                    <Label key={v} color="blue" className={"label-permissions"}>
                        {v}
                    </Label>));
            default:
                return val;
        }
    };

    const getCellByType = (col, val, row) => {
        switch (col.fieldType) {
            case 'enum':
                return ((col.items || []).find(i => i.value === val) || {}).text;
            case 'multiselect':
                return (val || []).filter(f => f).map(v =>
                    <Label key={v} color="blue" className={"label-permissions"}>
                        {v}
                    </Label>);
            case 'enums':
                return (col.items || []).filter(f => (val || []).includes(f.value)).map(v =>
                    <Label key={v} color="blue" className={"label-permissions"}>
                        {v.text}
                    </Label>);
            case 'checkbox':
                return <div className="checkbox-field">
                    <Checkbox
                        checked={val}
                        disabled
                    />
                </div>;
            case 'withError':
                return <div>
                    {val} {
                    !!row[col.errorFrom] && <Icon
                        name="warning circle"
                        color="red"
                        size="large"
                    />
                }
                </div>;
            default:
                return val;
        }
    };

    const handleDelete = async (rowId, dictionary) => {
        const deletedMessage = customDeletedText ? customDeletedText(allRows, rowId) : (deletedText || 'Запись удалена');
        if (dictionarySettings.isNewType && newRows.find(r => r.id === rowId)) {
            setNewRows(newRows.filter(r => r.id !== rowId));
            setDeleteRow(null);
            toast.success(deletedMessage);
        } else {
            await deleteDictionaryItem(dictionarySettings.urlName, rowId).then(() => {
                setDeleteRow(null);
                getRequest({...filters, sortBy, sortDir}).then(() => updateFilterLists(dictionary));
                toast.success(deletedMessage);
            });
        }
    };

    const [newRows, setNewRows] = useState([]);
    const [editRows, setEditRows] = useState([]);
    const [greenRows, setGreenRows] = useState([]);

    const add = (data) => {
        if (dictionarySettings.isNewType) {
            let defaultValues = {
                isNew: true,
                id: 'isNew_' + Math.random(),
                ...(dictionarySettings.defaultsDataForAdd || {})
            };
            if (!data) getHeaders().forEach(h => {
                if (![undefined, null].includes(h.default)) defaultValues[h.key] = h.default;
            });
            else defaultValues = {...data, ...defaultValues};
            setNewRows(nR => ([...nR, defaultValues]));
        } else setOpenModal(true);
    };

    const allRows = newRows.filter(r => r.isNew).concat(rows);

    const handleCopy = (row) => {
        add(row);
    };

    const handleEdit = (id) => {
        if (editRows.includes(id)) {
            setNewRows(rs => [...rs.filter(row => row.id !== id)]);
        } else {
            setNewRows(rs => [...rs, rows.find(row => row.id === id)]);
        }
    };

    useEffect(() => {
        setEditRows(newRows.map(r => r.id));
    }, [newRows]);

    const handleSave = (form) => {
        if (dictionarySettings) {
            const oldId = form.id;
            const func = !(form.id && !form.isNew) ? createDictionaryItem : updateDictionaryItem;

            const formData = {...form};
            if (form.isNew) {
                delete formData.isNew;
                delete formData.id;
            }
            const multiselectHeadersKeys = dictionarySettings.headers.filter(h => ["multiselect", "enums"].includes(h.fieldType)).map(h => h.key);

            if (multiselectHeadersKeys.length) {
                multiselectHeadersKeys.forEach(k => {
                    formData[k] = Array.isArray(formData[k]) ? formData[k].filter(f => f) : formData[k];
                });
            }

            func(dictionarySettings.urlName, {...(preFilters || {}), ...formData}).then(id => {
                getRequest({...filters, sortBy, sortDir}).then(() => {
                    updateFilterLists(dictionary);
                    setNewRows(rows => rows.filter(r => r.id !== oldId));
                    setGreenRows(rows => [...rows, id || form.id]);
                    setTimeout(() => setGreenRows(rows => [...rows.filter(r => r !== (id || form.id))]), 5000);
                });
            });
        }
    };

    const refresh = () => getRequest({...filters, sortBy, sortDir});

    const actions = (row, isEdit) => {
        const canSave = () => {
            let allRequiredAreFilled = checkRequiredFields(getHeaders(), row);

            if (!allRequiredAreFilled) {
                const errorText = customCheckRequired ? customCheckRequired(dictionarySettings, row) : false;
                setError && setError(errorText);
                return false;
            } else {
                let error = dictionarySettings.fullCopiesAreProhibited
                    ? isFullCopy(allRows, getHeaders(), row) && `Введенный ${(dictionarySettings.customText || {}).name} является дубликатом уже существующей записи. Требуется корректировка`
                    : false;
                if (customCheckError && !error) {
                    error = customCheckError(row, allRows);
                }
                setError && setError(error);
                return !error;
            }
        };
        return <Table.Cell textAlign="center" className="notifications__action-sticky">
            {
                customActions && customActions(row, refresh)
            }
            {(row.isNew || isEdit) && <Icon
                name="save"
                className="notifications__action"
                link
                title="Сохранить"
                size="large"
                disabled={false}
                color={canSave() ? "green" : "grey"}
                onClick={() => canSave() ? handleSave(row) : null}
            />}
            <Icon
                name="pencil"
                className="notifications__action"
                link
                title="Редактировать"
                size="large"
                color="blue"
                disabled={isOnlyView || row.isNew}
                onClick={() => handleEdit(row.id)}
            />
            <Icon
                name="copy outline"
                className="notifications__action"
                link
                title="Копировать"
                size="large"
                color="grey"
                disabled={isOnlyView || row.isNew || editRows.includes(row.id)}
                onClick={() => handleCopy(row)}
            />
            <Icon
                name="trash alternate"
                className="notifications__action"
                link
                title="Удалить"
                size="large"
                color="red"
                disabled={isOnlyView}
                onClick={() => setDeleteRow(row.id)}
            />
        </Table.Cell>;
    };

    const exportData = () => {
        setLoading(true);
        exportDictionary(dictionarySettings.urlName, !filters ? '' : '?' + qs.stringify(filters)).then(r => {
            setLoading(false);
            window.open(r.url, "_self");
        }).catch(e => {
            setLoading(false);
            console.log(e);
            toast.error('Произошла ошибка при экспорте');
        });
    };

    const callbackFunction = (data) => {
        (data || {}).url && window.open(data.url, "_self");
        getRequest({...filters, sortBy, sortDir});
    };

    const {
        importBtnName,
        addBtnName,
        name,
        deletedText
    } = (dictionarySettings.customText || {});

    const filterHeaders = [...getHeaders(), {}];

    return <div key={dictionary}>
        <Menu className="waybills-toolbar shd-inset dictionaries__menu" size="small" borderless>
            <Menu.Menu position="right" className="waybills-toolbar shd-inset dictionaries__menu">
                {
                    menuItems && menuItems.map(item => <Menu.Item
                        key={item.name}
                        onClick={() => item.onClick(refresh)}
                        disabled={item.disabled}
                    >
                        <Icon name={item.icon}/>{item.name}
                    </Menu.Item>)
                }
                <Menu.Item
                    onClick={() => add()}
                    disabled={isOnlyView}
                >
                    <Icon name="plus"/><T>{addBtnName || 'Добавить запись'}</T>
                </Menu.Item>
                {(dictionarySettings.isNewType && !!rows.length) && <Menu.Item
                    onClick={exportData}
                    disabled={isOnlyView}
                >
                    <Icon name="arrow up"/><T>Экспорт в Excel</T>
                </Menu.Item>}
                {dictionarySettings.isNewType && <FormImport
                    exampleBtnText={exampleBtnText}
                    exampleFile={exampleFile}
                    filter={dictionarySettings.idParamName && (preFilters || {})[dictionarySettings.idParamName]}
                    dropzoneName={'записями'}
                    header="Импорт записей из Excel"
                    saveBtnName="Загрузить"
                    id={dictionarySettings.urlName}
                    importMethod={importDictionary}
                    callbackFunction={callbackFunction}
                >
                    <Menu.Item disabled={isOnlyView}>
                        <Icon name="arrow down"/><T>{importBtnName || 'Импорт записей'}</T>
                    </Menu.Item>
                </FormImport>}
                {
                    customBtns && customBtns()
                }
            </Menu.Menu>
        </Menu>
        <div
            className="table-wrapper-grid dictionaries__table"
            style={style || {}}
        >
            <div>
                <Table celled sortable singleLine>
                    <Table.Header className="table-header-fixed">
                        <Table.Row>
                            {getHeaders().map((item, i) => (
                                <Table.HeaderCell
                                    id={`header-th-${item.key}`}
                                    key={`hc_${item.key}_${i}`}
                                    style={!item.sortable ? styleDisabledHeader : {}}
                                    sorted={item.sortable && sortBy === item.key ? (sortDir === '1' ? 'descending' : 'ascending') : null}
                                    onClick={item.sortable ? handleSort(item.key) : null}
                                    className="table-select-cell table-header-fixed__title"
                                >
                                    {item.text}
                                </Table.HeaderCell>
                            ))}
                            <Table.HeaderCell
                                className={`table-header-fixed__title w-20px  ${dictionarySettings.isNewType ? 'notifications__action-sticky' : ''}`}>
                                {dictionarySettings.isNewType ? 'Действия' : ''}
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            {filterHeaders.map(({key, filterType, items, filterCombineKey, separator}, index) => {
                                    const combineColumns = filterCombineKey && filterHeaders.filter(f => f.filterCombineKey === filterCombineKey);
                                    const isCombineFirstCol = combineColumns && (combineColumns[0] || {}).key === key;

                                    return (!filterCombineKey || isCombineFirstCol) && <Table.Cell
                                        colSpan={!filterCombineKey ? 1 : combineColumns.length}
                                        id={`filter-td-${key}`}
                                        style={styleDisabledHeader}
                                        key={`tc_${key}_${index}`}
                                        className={`table-select-cell dictionaries__filter_fixed ${(dictionarySettings.isNewType && index === getHeaders().length) ? 'notifications__action-sticky' : ''}`}
                                    >
                                        {getFilter(filterCombineKey || key, filterType, items, separator)}
                                    </Table.Cell>;
                                }
                            )}
                        </Table.Row>
                    </Table.Header>
                    {
                        !!allRows.length
                            && <Table.Body>
                                {
                                    allRows.map(row => (!row.isNew && !editRows.includes(row.id)) ? <Table.Row
                                            style={{cursor: 'pointer'}}
                                            key={'row_' + row.id}
                                            className={`${greenRows.includes(row.id) ? 'notifications__green-row' : `${(isBoolValue(row.isDefault) || (errorFlag && row[errorFlag])) ? 'notifications__red-row' : ''}`}`}
                                        >
                                            {
                                                getHeaders().map(column => (
                                                    <Table.Cell
                                                        style={{minWidth: ['multiselect', 'enums'].includes(column.fieldType) && '200px'}}
                                                        onClick={() => {
                                                            if (!dictionarySettings.isNewType) {
                                                                setOpenModal(true);
                                                                setId(row.id);
                                                            } else {
                                                                rowOnClick && rowOnClick(row);
                                                            }
                                                        }}
                                                        className="dictionaries__table-cell"
                                                        key={`rc_${column.key}_${row.id}`}>
                                                        {
                                                            dictionarySettings.isNewType ? getCellByType(column, row[column.key], row) : getCell(column.key, row[column.key])
                                                        }
                                                    </Table.Cell>
                                                ))
                                            }
                                            {
                                                dictionarySettings.isNewType ?
                                                    actions(row, false)
                                                    : <Table.Cell>
                                                        <Icon
                                                            name="delete"
                                                            link
                                                            title="Удалить"
                                                            size="large"
                                                            onClick={() => setDeleteRow(row.id)}
                                                        />
                                                    </Table.Cell>
                                            }
                                        </Table.Row>
                                        : <EditRow
                                            key={row.id}
                                            columns={getHeaders()}
                                            initialRow={newRows.find(r => r.id === row.id)}
                                            name={dictionarySettings.urlName}
                                            actions={actions}
                                        />)
                                }
                            </Table.Body>
                    }
                </Table>
                {!allRows.length && noRows}
            </div>
        </div>
        <Confirm
            open={!!deleteRow}
            cancelButton={'Отмена'.t}
            header={`Удалить ${name || 'элемент справочника'}?`}
            content={'Если да, то нажмите на кнопку “Ок”, если нет, то на кнопку “Отмена”'}
            onCancel={() => setDeleteRow(null)}
            onConfirm={() => handleDelete(deleteRow, dictionary)}
        />
        {openModal && <EditModal
            onClose={() => {
                setOpenModal(false);
                setId(null);
                getRequest({...filters, sortBy, sortDir}).then(() => updateFilterLists(dictionary));
            }}
            open={openModal}
            id={id}
            dictionary={dictionary}
        />}
    </div>;
};

export default DictionariesTable;
