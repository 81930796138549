import {del, get, post, put, upload} from '../services/http';
import qs from "query-string";

export async function getDictionaryTypes() {
    const result = await get(`dictionaryTypes`);
    return result.map(dt => {
        return {
            key: dt.key.toString(),
            value: dt.key.toString(),
            text: dt.value
        };
    });
};

export const getClientNames = async () => {
    return await get(`avisationDictionary/clients`);
};

export const searchCompanyNames = async (name) => {
    return await get(`dictionaries/companyNames/search/${name}`);
};

export const searchClientNames = async (name) => {
    return await get(`companies/search/fmid/${name}`);
};

export const getRegions = async (search) => {
    return await get(`regionsDictionary${search}`);
};

export const getCompanies = async () => {
    return await get(`networksDictionary/companies`);
};

export const getRegionsFromCities = async () => {
    return await get(`regionsDictionary/fromCities`);
};

export const getCounties = async () => {
    return await get(`regionsDictionary/countries`);
};

export const getTimezones = async () => {
    return await get(`regionsDictionary/timezones`);
};

export const getPointFMIDsFromCities = async () => {
    return await get(`networksDictionary/pointFMIDs`);
};

export const getPointFMIDsByCompanies = async (names) => {
    const namesString = qs.stringify({companyNames: names});
    return await get(`networksDictionary/points/pointFMIDs?${namesString}`);
};

export const getDictionaryItemsData = async (name, search) => {
    return await get(`${name}Dictionary${search || ''}`);
};

export const exportDictionary = async (name, search) => {
    return await post(`${name}Dictionary/export${search}`);
};

export const importDictionary = async (name, formData, filter) => {
    return await upload(`${name}Dictionary/import${filter ? `/${filter}` : ''}`, formData);
};

export const getDictionaryItemData = async (name, id) => {
    return await get(`${name}Dictionary/${id}`);
};

export const createDictionaryItem = async (name, data) => {
    return await post(`${name}Dictionary/create`, data);
};

export const updateDictionaryItem = async (name, data) => {
    return await put(`${name}Dictionary/update`, data);
};

export const deleteDictionaryItem = async (name, id) => {
    return await del(`${name}Dictionary/delete/${id}`);
};

export const dictionaryIsNotEmpty = async (name, id) => {
    return await get(`${name}Dictionary/any/${id}`);
};

export const getCarTypesForField = async (data) => {
    return await get(`carTypeDictionary/bywaybill?${qs.stringify(data)}`);
}

export async function copyFromTo(name, idFrom, idTo) {
    return await post(`${name}Dictionary/copyFrom/${idFrom}/${idTo}`);
}
