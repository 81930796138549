import React from 'react';
import {Dropdown, Input} from "semantic-ui-react";
import {fieldTypes} from "../../../api/model/ImportForms";
import MaskInput from "../../../components/inputs/MaskInput";

const RuleInput = ({inputType, value, info, isInt, canBeNegative, clearable, ...props}) => {
    const inputByType = (type) => {
        switch (type) {
            case fieldTypes.DROPDOWN:
                return <Dropdown
                    {...props}
                    clearable={clearable}
                    selectOnBlur={false}
                    fluid
                    selection
                    value={value !== undefined ? value : null}
                />;
            case fieldTypes.MULTISELECT:
                return <Dropdown
                    {...props}
                    selectOnBlur={false}
                    fluid
                    selection
                    multiple
                    value={value !== undefined ? value : null}
                />;
            case fieldTypes.NUMBER:
                return <Input
                    {...props}
                    onChange={(e, {name, value}) => {
                        let val = value;
                        if (!canBeNegative) {
                            val = val.replaceAll('-', '');
                        }
                        if (isInt) {
                            val = val.replaceAll('.', '').replaceAll(',', '');
                        }
                        props.onChange(e, {name, value: val});
                    }}
                    fluid
                    type={inputType}
                    value={value || ''}
                />;
            case fieldTypes.PLUS_DAYS:
                return <MaskInput
                    {...props}
                    regex={/^D\+(\d*)$/}
                    value={value || 'D+'}
                    fluid
                    noLabel
                    onChange={({value}) => {
                        props.onChange(null, {name: props.name, value});
                    }}
                />;
            default:
                return <Input
                    {...props}
                    fluid
                    type={inputType}
                    value={value || ''}
                />;
        }
    };
    return (
        <div className="rule-input">
            {inputByType(inputType)}
            {info && <div className="rule-input__info">{info}</div>}
        </div>
    );
};

export default RuleInput;