import UserPermissions from "./UserPermissions";

export default class WaybillColumn {
    /**
     * @param {string} key
     * @param {string} text
     * @param {number} order
     * @param {boolean} sortable
     * @param {boolean} [hide]
     * @param {boolean} [system]
     */
    constructor(key, text, order, sortable = false, hide = false, system = false) {
        this.key = key;
        this.text = text;
        this.order = order;
        this.sortable = sortable;
        this.hide = hide;
        this.system = system;
    }
}

export const waybillColumnsSet = [
    new WaybillColumn("fmid", "Идентификатор FM", 1, true, false, true),
    new WaybillColumn("returnWaybillId", "Идентификатор возврата", 2, true, false, true),
    new WaybillColumn("id", "Идентификатор FMCP", 3, true, false),
    new WaybillColumn("parentPortalId", "Идентификатор основной заявки", 4, true, false),
    new WaybillColumn("clientId", "Клиент, searchname", 5, false, true),
    new WaybillColumn("clientName", "Клиент, наименование", 6, false, true),
    new WaybillColumn("providerName", "Поставщик, searchname", 7, false, false),
    new WaybillColumn("slotId", "Номер слота", 8, true, true),
    new WaybillColumn("waybillType", "Тип заявки", 9, false, false),
    new WaybillColumn("type", "Тип сервиса доставки", 10, false),
    new WaybillColumn("refs", "№ заказа грузополучателя", 11),
    new WaybillColumn("torg12No", "№ накладной (Торг12)", 12),
    new WaybillColumn("warehouseOrderNo", "№ Заказа грузоотправителя", 13, false, false),
    new WaybillColumn("cargoType", "Тип груза", 14, true, true),
    new WaybillColumn("unitsCount", "Количество грузовых единиц", 15, true),
    new WaybillColumn("unitsType", "Тип грузовых единиц", 16, true),
    new WaybillColumn("totalWeight", "Вес, кг", 17, true),
    new WaybillColumn("shippingTemperatureCondition", "Температурный режим", 18, true, true),
    new WaybillColumn("vehicleCapacity", "Грузоподъемность", 19, true, true),
    new WaybillColumn("carTypeName", "Тип ТС", 20, true, false),
    new WaybillColumn("status", "Статус доставки", 21, true),
    new WaybillColumn("statusAnomaly", "Статус аномалии", 22, true, true),
    new WaybillColumn("shipper", "Наименование грузоотправителя", 23, true),
    new WaybillColumn("pointLoadingAddressSettlement", "Адрес грузоотправителя (Город)", 24, true, false),
    new WaybillColumn("pointLoadingAddress", "Адрес грузоотправителя", 25, true, false),
    new WaybillColumn("consignee", "Наименование грузополучателя", 26, true),
    new WaybillColumn("pointUnloadingAddressSettlement", "Адрес грузополучателя (Город)", 27, true),
    new WaybillColumn("pointUnloadingAddress", "Адрес грузополучателя", 28, true),
    new WaybillColumn("loadingDate", "Плановые дата и время загрузки", 29, true, false),
    new WaybillColumn("loadingDateFact", "Фактическая дата прибытия на загрузку", 30, true, true),
    new WaybillColumn("loadingTimeFact", "Фактическое время прибытия на загрузку", 31, true, true),
    new WaybillColumn("dispatchDateFact", "Фактическая дата убытия с загрузки", 32, true, true),
    new WaybillColumn("dispatchTimeFact", "Фактическое время убытия с загрузки", 33, true, true),
    new WaybillColumn("services", "Доп. услуги разгрузки", 34, false, true),
    new WaybillColumn("deliveryDate", "Плановые дата и время доставки", 35, true, false),
    new WaybillColumn("unloadingDateFact", "Фактическая дата прибытия на разгрузку", 36, true, true),
    new WaybillColumn("unloadingTimeFact", "Фактическое время прибытия на разгрузку", 37, true, true),
    new WaybillColumn("unloadingLeaveDateFact", "Фактическая дата убытия с разгрузки", 38, true, false),
    new WaybillColumn("unloadingLeaveTimeFact", "Фактическое время убытия с разгрузки", 39, true, false),
    new WaybillColumn("pickupType", "Способ доставки груза", 40, true, false),
    new WaybillColumn("driverName", "Водитель первого плеча", 41, true, false),
    new WaybillColumn("truckLicensePlate", "ТС первого плеча", 42, true, false),
    new WaybillColumn("avisationStatus", "Статус авизации", 43, true, false),
    new WaybillColumn("reservationId", "Номер Визита", 44, true, true),
    new WaybillColumn("reservation", "Таймслот", 45, true, true),
    new WaybillColumn("reservationDriver", "Водитель (авизация)", 46, true, true),
    new WaybillColumn("reservationDriverPhoneNumber", "Телефон водителя (авизация)", 47, true, true),
    new WaybillColumn("document", "Товаросопроводительные документы", 48, false, true),
    new WaybillColumn("dateCreated", "Дата создания заявки", 49, true, true),
    new WaybillColumn("cost", "Стоимость груза", 50, true),
    new WaybillColumn("tsunamiSendingMessage", "Отправка в Цунами", 51, true),
    new WaybillColumn("readiness", "Исполнение, %", 52),
    new WaybillColumn("isArchive", "Архивация", 53),
    new WaybillColumn("fmRelation", "Лицо застраховавшее заявку", 54, true),
];

export const columnsByRole = (colsSet, role) => {
    const clientText = 'Клиент';
    let cols = [...colsSet];
    if ((role.name || '').toUpperCase().includes(clientText.toUpperCase())) {
        cols = cols.filter(c => c.key !== 'tsunamiSendingMessage');
    }
    if (!role.permissions.includes(UserPermissions.INSURANCE_INFO)) {
        cols = cols.filter(c => c.key !== 'fmRelation');
    }
    return cols;
};

export const auctionColumnsSet = [
    new WaybillColumn("waybillId", "Идентификатор FMCP", 1, true, false, true),
    new WaybillColumn("clientFMID", "Клиент, searchname", 2, true, false),
    new WaybillColumn("clientName", "Клиент, наименование", 3, true, false),
    new WaybillColumn("providerName", "Поставщик", 4, true, false),
    new WaybillColumn("auctionStatus", "Статус аукциона", 5, true, false),
    new WaybillColumn("deadline", "Дедлайн на принятие решения по размещению заявки", 6, true, false),
    new WaybillColumn("auctionType", "Тип аукциона", 7, true, false),
    new WaybillColumn("auctionStart", "Дата и время начала аукциона", 8, true, false),
    new WaybillColumn("auctionEnd", "Дата и время окончания аукциона", 9, true, false),
    new WaybillColumn("initialOffer", "Начальное предложение", 10, true, false),
    new WaybillColumn("termInsuranceWithoutVATCost", "Стоимость страхования сроков без НДС", 11, true, false),
    new WaybillColumn("cargoInsuranceWithoutVATCost", "Стоимость страхования грузов без НДС", 12, true, false),
    new WaybillColumn("bestBet", "Лучшее предложение", 13, true, false),
    new WaybillColumn("loadingDate", "Дата и время загрузки", 14, true, false),
    new WaybillColumn("sender", "Грузоотправитель", 15, true, false),
    new WaybillColumn("loadingAddress", "Адрес загрузки", 16, true, false),
    new WaybillColumn("orderNumbers", "Номера заказов", 17, true, false),
    new WaybillColumn("torg12", "Торг 12", 18, true, false),
    new WaybillColumn("cargoUnits", "Груз. единицы", 19, true, false),
    new WaybillColumn("totalWeightKgBrutto", "Вес, кг", 20, true, false),
    new WaybillColumn("cargoCost", "Стоимость груза", 21, true, false),
    new WaybillColumn("deliveryDate", "Дата и время доставки", 22, true, false),
    new WaybillColumn("receiver", "Грузополучатель", 23, true, false),
    new WaybillColumn("deliveryAddress", "Адрес доставки", 24, true, false),
    new WaybillColumn("cargoType", "Тип груза", 25, true, false),
    new WaybillColumn("carType", "Тип ТС", 26, true, false),
    new WaybillColumn("transportNumber", "ТС", 27, true, false),
    new WaybillColumn("shippingTemperatureCondition", "Температурный режим", 28, true, true),
    new WaybillColumn("vehicleCapacity", "Грузоподъемность", 29, true, true),
    new WaybillColumn("driver", "Водитель", 30, true, false),
    new WaybillColumn("loadingServices", "Доп. услуги (на загрузке)", 31, true, true),
    new WaybillColumn("unloadingServices", "Доп. услуги (на выгрузке)", 32, true, true),
];
