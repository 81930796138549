import qs from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { companyPointUpdate, getCompanyPoints } from '../../api/companies';
import { LinkInfo } from '../../layout/navbar/Navbar';
import Shield from '../../layout/page/shield/Shield';
import {ContextFooter, ContextNavi, ContextUser} from '../../services/context';
import '../admin/points/Points.css';
import PointGridHeader from '../admin/points/pointsGrid/PointGridHeader';
import PointGridRows from '../admin/points/pointsGrid/PointGridRows';
import PointsToolbar from './pointsToolbar/PointsToolbar';
import PointsScrollPreloader from '../_shared/pointsScrollPreloader/PointsScrollPreloader';
import {pointColumnsSet} from "../../api/model/PointColumn";

export default withRouter(Points);

function Points({location}) {

    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [selectedPoints, setSelectedPoints] = useState([]);
    const contextNavi = useContext(ContextNavi);
    const contextFooter = useContext(ContextFooter);
    const contextUser = useContext(ContextUser);
    const headersNew = pointColumnsSet.filter(i => !i.hide || (i.hide && Array.isArray(i.permission) ? i.permission.find(p => contextUser.current.permissions.includes(p)) : contextUser.current.permissions.includes(i.permission)));
    const keys = headersNew.map(h => h.key);
    const [isAll, setIsAll] = useState(false);

    async function fetchData(query) {
        setLoading(true);
        try {
            const stringQuery = `?${qs.stringify(query)}`;
            let result = await getCompanyPoints(stringQuery);
            result = result.map((item) => {
                const { pointId, companyId } = item;
                return {
                    ...item,
                    id: `${pointId}%${companyId}`,
                    forExport: {
                        pointId,
                        companyId
                    }
                };
            });
            return result;
        } finally {
            setLoading(false);
            !loaded && setLoaded(true);
        }
    };

    async function updateRows() {
        const query = qs.parse(location.search, { ignoreQueryPrefix: true });
        const rows = await fetchData(query);
        setRows(rows);
    };

    async function onPointChange(data) {
        const { companyId, pointId } = data;
        await companyPointUpdate(companyId, pointId, data);
        await updateRows();
    };

    useEffect(() => {
        const load = async () => {
            updateRows();
        };
        load();

    }, [location.search]);

    useEffect(() => {
        setSelectedPoints([]);
        if (isAll) {
            setSelectedPoints(rows.map(i => i.id))
            ;        }
    }, [rows]);

    useEffect(() => {
        rows.length
            ? contextFooter.setIndicator(() =>
                <div className="table-footer">
                    {selectedPoints.length
                        ? `Выбрано: ${selectedPoints.length} / `
                        : null}
                    {rows.length} записей
                </div>)
            : contextFooter.setIndicator(null);
        return () => contextFooter.setIndicator(null);
    }, [rows.length, selectedPoints.length]);

    useEffect(() => {
        const title = "Мои адреса".t;
        contextNavi.setItems([new LinkInfo(title, "/points")]);
    }, []);

    return (
        <Shield loading={loading && !loaded} loadingOver={false}>
            <PointsToolbar
                query={location.search}
                rows={rows}
                updateRows={updateRows}
                selectedPoints={selectedPoints}
            />
            <div className="table-wrapper">
                <div className="table-scroll">
                    <PointGridHeader
                        headers={headersNew}
                        hasRows={rows.length}
                        selectedPoints={selectedPoints || []}
                        getRowsIds={() => rows.map(r => r.id)}
                        setSelectedPoints={setSelectedPoints}
                        isClient
                        setIsAll={setIsAll}
                    />
                </div>
                <div className="table-scroll points-table table-empty">
                    <PointGridRows
                        rows={rows}
                        keys={keys}
                        selectedPoints={selectedPoints || []}
                        setSelectedPoints={(val) => {
                            if (isAll && val.length !== rows.length) {
                                setIsAll(false);
                            }
                            setSelectedPoints(val)}}
                        isClient
                        onChange={onPointChange}
                    />
                    <PointsScrollPreloader {...{ location, rows, fetchData }} setRows={(val)=> {
                        if (isAll) {
                            setSelectedPoints(val.map(i => i.id));
                        }
                        setRows(val)}}/>

                </div>
            </div>
        </Shield>
    );
}
