import React, {useEffect, useState} from 'react';
import InputDropdown from "./InputDropdown";
import {DictionariesSettings} from "../../api/model/Dictionaries";
import {getDictionaryItemsData} from "../../api/dictionaries";

const SelectFromDictionary = ({value, onChange, dictionaryKey, multiple, placeholder, clearable}) => {
    const [options, setOptions] = useState([]);

    const dictionarySettings = DictionariesSettings.find(h => h.key.toString() === dictionaryKey) || {};

    const {
        urlName
    } = dictionarySettings;

    const getItems = async () => {
        const rows = await getDictionaryItemsData(urlName);
        rows && setOptions(rows.map(row => ({key: row.id, value: row.id, text: row.name})));
    };

    useEffect(() => {
        getItems();
    }, []);

    const handleAdd = (e, {value}) => {
        setOptions(options => [...options, {text: value, value, key: value}]);
    };

    return (
        <InputDropdown
            id={dictionaryKey}
            value={value}
            onChange={onChange}
            handleAdd={handleAdd}
            placeholder={placeholder}
            multiple={multiple}
            options={options}
            clearable={clearable}
            additionLabel='Добавить '
        />
    );
};

export default SelectFromDictionary;