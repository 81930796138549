const UserPermissions = {
    WAYBILLS: 1,
    WAYBILL_EDIT: 2,
    WAYBILL_EDIT_COMPANY: 7,
	COMPANIES: 100,
	PROVIDERS: 150,
    POINTS: 200,
    USERS: 300,
    USERPROFILES: 400,
    WAYBILL_SOFT_DELETE: 3,
    GETTING_NOTIFICATIONS: 4,
    COMPANY_POINTS: 5,
    FMID_ADDRESS: 6,
    REGISTRIES: 500,
    COMPLETION_REGISTRIES: 501,
    REGISTRIES_NOTIFICATIONS: 502,
    REGISTRIES_DELETE : 503,
    BANNERS: 600,
    BANNER_NOTIFICATIONS: 601,
    AUTOCOMPLETE: 700,
    STANDARD_AUTOCOMPLETE: 701,
    SCHEDULES: 800,
    SCHEDULES_EDIT: 801,
    SCHEDULES_SETTINGS_VIEAW: 802,
    RETURN_WAYBILLS_EDIT: 900,
    DISALLOW_AVISATION: 1000,
    MAPPING_CODE: 1100,
    MAPPING_CODE_VIEW: 1110,
    REGISTRIES_EDIT_PERIOD: 504,
    MANGING_NOTIFICATIONS: 1200,
    DICTIONARIES_EDIT: 1300,
    TIMELIMITS: 702,
    AUCTIONS: 1400,
    AUCTIONS_EDIT: 1401,
    IMPORT_FORMS_AND_RULES_MANAGE: 1500,
    IMPORT_FORMS_AND_RULES_VIEW: 1501,
    INSURANCE_INFO: 1600
};

export default UserPermissions;
