import React, {useContext, useEffect, useState} from 'react';
import {Confirm, Divider, Icon, Menu} from "semantic-ui-react";
import WbColumnsSetup from "./wbGridToolbar/wbColumnsSetup/WbColumnsSetup";
import {auctionColumnsSet} from "../../../api/model/WaybillColumn";
import T from "../../../components/Translate";
import {
    auctionCancel,
    auctionCompleteWithWinner,
    auctionDelete,
    auctionsExport,
    copyWaybill,
    createWaybill,
    wbIsEdit
} from "../../../api/waybills";
import {AuctionCancelledStatuses, AuctionStatusEnum} from "../../../api/model/Waybill";
import useReactRouter from "use-react-router";
import AuctionStartModal from "../wbEdit/auctionStartModal";
import {UserRolesEnum} from "../../../api/usersRoles";
import AuctionRulesModal from "../../auctionRules";
import {ContextUser} from "../../../services/context";
import UserPermissions from "../../../api/model/UserPermissions";

const AuctionsToolbar = ({selectedRows, setSelectedIds, location, loading, refresh}) => {
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const contextUser = useContext(ContextUser);
    const canAuctionEdit = contextUser.current.permissions.includes(UserPermissions.AUCTIONS_EDIT);

    const selectedIds = selectedRows.map(r => r.id);
    const iconName = "weight";
    const numberStyle = {
        fontSize: '15px',
    };
    const descrStyle = {
        color: '#9e9e9e'
    };
    const value = selectedRows && selectedRows.length
        ? selectedRows.filter(sR => sR.bestBet).length
        : 0;

    const exportClick = async () => {
        const fileInfo = await auctionsExport(selectedRows.length ? `?id=${selectedRows.map(sR => sR.id).join(',')}` : location.search);
        if (!fileInfo.error) {
            window.open(`/api/file/${fileInfo.id}`, "_blank");
        }
    };

    async function auctionDeleteDraft() {
        setIsDeleteConfirmOpen(false);
        loading(true);
        try {
            const ids = selectedRows.map(sr => sr.id);
            await auctionDelete(ids);
            setSelectedIds([]);
            refresh();
        } finally {
            loading(false);
        }
    }

    const canDelete = canAuctionEdit && selectedRows.length > 0 && selectedRows.every(wb => wb.auctionStatus === AuctionStatusEnum.DRAFT);

    const canStart = canAuctionEdit && selectedRows.length === 1 && [AuctionStatusEnum.CREATED, AuctionStatusEnum.CANCELLED].includes(selectedRows[0].auctionStatus);
    const canExtend = canAuctionEdit && selectedRows.length === 1 && [AuctionStatusEnum.BIDDING, AuctionStatusEnum.REQUIRES_DECISION].includes(selectedRows[0].auctionStatus);
    const canCompleteWithWinner = canAuctionEdit && canExtend && selectedRows[0].bestBet;

    const [isEdit, setIsEdit] = useState(false);
    const canCancel = canAuctionEdit && selectedRows.length === 1 && isEdit && !AuctionCancelledStatuses.includes(selectedRows[0].auctionStatus);
    const canCopy = selectedIds && selectedIds.length === 1;

    const getIsEdit = async () => {
        const result = await wbIsEdit(selectedRows[0].waybillId);
        setIsEdit(result && result.value);
    };

    useEffect(() => {
        selectedRows.length === 1 && getIsEdit();
    }, [(selectedRows[0] || {}).id]);

    const {history} = useReactRouter();

    const [modalRules, setModalRules] = useState(false);
    const [modalRulesCallback, setModalRulesCallback] = useState(null);

    const create = async () => {
        loading(true);
        const dto = await createWaybill(true);

        if (dto && dto.id) {
            history.push(`/waybills/auctions/${dto.id}/edit`);
        } else {
            loading(false);
        }
    };

    const onCreate = async () => {
        const rulesView = !contextUser.current.acceptanceOfTheAuctionRules && contextUser.current.role.id === UserRolesEnum.CLIENT;
        if (!rulesView) {
            create();
        } else {
            setModalRules(rulesView);
            setModalRulesCallback({f: create});
        }
    };

    const cancel = () => {
        loading(true);
        auctionCancel(selectedRows[0].id)
            .then(() => {
                refresh();
                loading(false);
            })
            .catch(() => loading(false));
    };

    const completeWithWinner = () => {
        loading(true);
        auctionCompleteWithWinner(selectedRows[0].id)
            .then(() => {
                refresh();
                loading(false);
            })
            .catch(() => loading(false));
    };

    const createCopy = async () => {
        loading(true);
        try {
            const newId = await copyWaybill(selectedIds[0], true);
            history.push(`/waybills/auctions/${newId}/edit`);
        } catch (err) {
            loading(false);
        }
    };


    return (
        <Menu className="waybills-toolbar shd-inset" style={{marginBottom: '0'}} size="small" borderless>
            <Menu.Item as="div" className="p-t-0 p-b-0" style={{color: selectedIds.length ? 'inherit' : '#9e9e9e'}}>
                <Icon
                    name={selectedIds.length ? iconName : iconName}
                    style={{
                        fontSize: "15px",
                        marginLeft: "7px",
                        color: selectedIds.length ? '#106ebe' : '#9e9e9e',
                        marginTop: "-2px"
                    }}
                />
                &nbsp;<span style={numberStyle}>{value}</span>&nbsp;<span
                style={descrStyle}><T>ставок</T></span>
            </Menu.Item>
            <Menu.Menu position="right">
                <Menu.Item disabled={!canCopy} onClick={createCopy}>
                    <Icon name="copy"/><T>Создать копию аукциона</T>
                </Menu.Item>
                <Menu.Item disabled={!canAuctionEdit} onClick={create}>
                    <Icon name="plus"/><T>Создать аукцион</T>
                </Menu.Item>
                <AuctionStartModal fetchData={refresh} selectedItem={selectedRows.length && selectedRows[0]}>
                    <Menu.Item disabled={!canStart}>
                        <Icon name="legal"/><T>Запустить аукцион</T>
                    </Menu.Item>
                </AuctionStartModal>
                <Menu.Item onClick={cancel} disabled={!canCancel}>
                    <Icon name="times circle outline"/><T>Отменить аукцион</T>
                </Menu.Item>
                <AuctionStartModal fetchData={refresh} isExtension selectedItem={selectedRows.length && selectedRows[0]}>
                    <Menu.Item disabled={!canExtend}>
                        <Icon name="clock outline"/><T>Продлить аукцион</T>
                    </Menu.Item>
                </AuctionStartModal>
                <Menu.Item onClick={completeWithWinner} disabled={!canCompleteWithWinner}>
                    <Icon name="truck"/><T>Разместить заявку</T>
                </Menu.Item>
                <Menu.Item onClick={() => setIsDeleteConfirmOpen(true)} disabled={!canDelete}>
                    <Icon name="trash"/><T>Удалить черновик(и)</T>
                </Menu.Item>
                <Confirm open={isDeleteConfirmOpen}
                         dimmer="inverted"
                         header={'Удаление черновика(ов)'.t}
                         content={'Восстановление данных черновика после удаления невозможно. Вы уверены?'.t}
                         confirmButton={'Удалить'.t}
                         cancelButton={'Отмена'.t}
                         onCancel={() => setIsDeleteConfirmOpen(false)}
                         onConfirm={auctionDeleteDraft}/>
                <Menu.Item
                    onClick={exportClick}
                >
                    <Icon name="arrow up"/><T>Экспорт в Excel</T>
                </Menu.Item>
                <Menu.Item fitted>
                    <Divider vertical/>
                </Menu.Item>
                <WbColumnsSetup
                    allColumns={auctionColumnsSet}
                    colsKey={'auctionsColumns'}
                    setColsKey={'setAuctionsColumns'}
                >
                    <Menu.Item style={{display: 'inherit'}} title={"Настройка полей таблицы".t}>
                        <Icon name="setting"/>
                    </Menu.Item>
                </WbColumnsSetup>
            </Menu.Menu>
            <AuctionRulesModal open={modalRules} setOpen={setModalRules} callback={(modalRulesCallback || {}).f}/>
        </Menu>
    );
};

export default AuctionsToolbar;